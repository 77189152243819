import { Box, Card, CardContent, FormControl, Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash/fp';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SaleTransactionVehicleFields } from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import useBasicStyle from '../../../../layouts/BasicLayout/useBasicStyles';
import { useHandleError } from '../../../../utilities/handleErrors';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';
import { useStyles } from '../../CreateSaleTransaction/CreateSaleTransactionBody';
import parse from './parser';

export type VehiclePrefillFormValues = {
    raw: string;
};

const ltaLink = 'https://vrl.lta.gov.sg/lta/vrl/action/pubfunc?ID=EnquireRebateBeforeDeReg';

const parsePrefilledFields = <T extends { vehicle?: SaleTransactionVehicleFields }>(
    raw: string | null | undefined,
    formValues: T,
    initialValues: T
) => {
    const parsed = parse(raw);

    if (isEmpty(parsed)) {
        return { parsed: null, updatedValues: null };
    }

    const currentValues = isEmpty(formValues) ? initialValues : formValues;

    return { parsed, updatedValues: { ...currentValues, vehicle: { ...currentValues?.vehicle, ...parsed } } };
};

const formValidator = validators.requiredNonEmptyString('raw');

export type VehiclePrefillFormProps<T extends { vehicle?: SaleTransactionVehicleFields }> = {
    // initial raw value
    prefillRaw: string;

    formValues?: T;
    initialValues?: T;
    onComplete: (result: { raw?: string; parsed?: Partial<SaleTransactionVehicleFields>; updatedValues?: T }) => void;

    // header which contains submit button
    header: JSX.Element | ReactNode;
};

const VehiclePrefillForm = <T extends { vehicle?: SaleTransactionVehicleFields }>({
    prefillRaw,
    formValues,
    initialValues,
    onComplete,
    header,
}: VehiclePrefillFormProps<T>) => {
    const styles = useStyles();
    const theme = useTheme();
    const basicStyles = useBasicStyle();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    useTranslation(['saleTransactionsPage']);

    const onSubmit = useHandleError(
        async ({ raw }: VehiclePrefillFormValues) => {
            const { parsed, updatedValues } = parsePrefilledFields(raw, formValues, initialValues);
            if (onComplete) {
                onComplete({ raw, parsed, updatedValues });
            }
        },
        [onComplete, formValues, initialValues]
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={{ raw: prefillRaw }} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    {header}
                    <Box className={basicStyles.mainDesktop}>
                        <Box className={styles.root} mb={isSmall ? 10 : 0}>
                            <Grid container>
                                <Grid xs={12} item>
                                    <Card>
                                        <CardContent>
                                            <Box mb={2}>
                                                <Typography>
                                                    <Trans i18nKey="saleTransactionsPage:prefill.note">
                                                        <Link
                                                            color="secondary"
                                                            href={ltaLink}
                                                            target="_blank"
                                                            underline="always"
                                                        >
                                                            link
                                                        </Link>
                                                    </Trans>
                                                </Typography>
                                            </Box>
                                            <Grid spacing={2} container>
                                                <Grid lg={4} md={6} xs={12} item>
                                                    <FormControl fullWidth>
                                                        <OutlinedInputField name="raw" rows={10} multiline />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default VehiclePrefillForm;
