import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { PreviewSaleTransactionDataFragment, BiddingStatus } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import useLiveDuration from '../../../utilities/useLiveDuration';

export type RemainBiddingDurationProps = {
    transaction: PreviewSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#F50537',
            padding: theme.spacing(1),
            borderRadius: '5px 0 0 5px',
            maxWidth: `calc(100% - ${theme.spacing(5)}px)`,
            top: '100%',
            transform: 'translate(0, -50%)',
        },
        content: {
            marginLeft: theme.spacing(1),
            color: theme.palette.secondary.contrastText,
        },
    })
);

const RemainBiddingDuration = ({ transaction }: RemainBiddingDurationProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    if (transaction.latestBiddingSession?.status !== BiddingStatus.Current) {
        return null;
    }

    const liveDuration = useLiveDuration(transaction.latestBiddingSession.endPeriod);

    return (
        <Box className={styles.root} display="flex" justifyContent="center" position="absolute">
            <ScheduleIcon className={commonStyles.primary2} />
            <Typography className={styles.content}>{liveDuration}</Typography>
        </Box>
    );
};

export default RemainBiddingDuration;
