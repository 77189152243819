import { createContext, useContext, useMemo, useReducer } from 'react';
import { VehicleDiagramComment } from '../components/VehicleDiagram';
import { CreateSaleTransactionFormValues } from '.';

export enum Page {
    VehiclePrefill = 'vehiclePrefill',
    VehicleForm = 'vehicleForm',
    OfferSession = 'offerSession',
}

export const defaultValue: CreateSaleTransactionFormValues = {
    vehicle: {
        number: '',
        beExported: true,
        intendedDeregistrationDate: null,
        make: '',
        model: '',
        primaryColour: '',
        manufacturingYear: null,
        engineNumber: '',
        chassisNumber: '',
        maximumPowerOutput: '',
        openMarketValue: null,
        originalRegistrationDate: null,
        firstRegistrationDate: null,
        transferCount: null,
        actualARFPaid: null,
        parfEligibility: true,
        parfEligibilityDate: null,
        coeExpiryDate: null,
        coeCategory: '',
        coePeriodYear: null,
        mileage: null,
        importMethod: '',
        secondaryColour: '',
        opcCashRebateAmount: null,
        opcCashRebateEligibilityExpiryDate: null,
        opcCashRebateEligibility: '',
        pqpPaid: null,
        totalRebateAmount: null,
        ltaMessage: '',
        coeRebateAmount: null,
        parfRebateAmount: null,
        qpPaid: null,
        setOfKeys: null,
        ownerHandbookCollected: false,
    },
    customerAssessment: '',
    photos: [],
    handover: {
        targetHandoverDateTime: null,
    },
    action: null,
};

export type State = {
    prefillRaw: string;
    formValue: CreateSaleTransactionFormValues;
    currentPage: Page;
    comments: VehicleDiagramComment[];
};

export type SetPrefillRaw = { type: 'setPrefillRaw'; prefillRaw: string };
export type SetFormValue = { type: 'setFormValue'; formValue: CreateSaleTransactionFormValues };
export type SetCurrentPage = { type: 'setCurrentPage'; currentPage: Page };
export type SetComments = { type: 'setComments'; comments: VehicleDiagramComment[] };

export type Action = SetPrefillRaw | SetFormValue | SetCurrentPage | SetComments;

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'setPrefillRaw':
            return { ...state, prefillRaw: action.prefillRaw };

        case 'setFormValue':
            return { ...state, formValue: action.formValue };

        case 'setCurrentPage':
            return { ...state, currentPage: action.currentPage };

        case 'setComments':
            return { ...state, comments: action.comments };

        default:
            return state;
    }
};

export const useCreateSalesTransactionReducer = () =>
    useReducer(reducer, {
        prefillRaw: '',
        formValue: defaultValue,
        currentPage: Page.VehiclePrefill,
        comments: [],
    });

export type ActionMethods = {
    setPrefillRaw: (prefillRaw: string) => void;
    setFormValue: (formValue: CreateSaleTransactionFormValues) => void;
    setCurrentPage: (currentPage: Page) => void;
    setComments: (comments: VehicleDiagramComment[]) => void;
};

export type CreateSalesTransactionContext = {
    state: State;
    actions: ActionMethods;
};

const Context = createContext<CreateSalesTransactionContext | null>(null);

export const useCreateSalesTransactionState = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useCreateSalesTransactionState must be used within a CreateSalesTransactionStateProvider');
    }

    return context;
};

export type CreateSalesTransactionStateProviderProps = {
    children: JSX.Element | React.ReactNode;
};

const CreateSalesTransactionStateProvider = ({ children }: CreateSalesTransactionStateProviderProps) => {
    const [state, dispatch] = useCreateSalesTransactionReducer();

    const actions = useMemo(
        (): ActionMethods => ({
            setPrefillRaw: prefillRaw => dispatch({ type: 'setPrefillRaw', prefillRaw }),
            setFormValue: formValue => dispatch({ type: 'setFormValue', formValue }),
            setCurrentPage: currentPage => dispatch({ type: 'setCurrentPage', currentPage }),
            setComments: comments => dispatch({ type: 'setComments', comments }),
        }),
        [dispatch]
    );

    const context = useMemo((): CreateSalesTransactionContext => ({ state, actions }), [state, actions]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default CreateSalesTransactionStateProvider;
