import { Button, FormControl, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useConsumeResetPasswordMutation } from '../../../api';
import OutlinedPasswordField from '../../../components/fields/OutlinedPasswordField';
import { PasswordAdornment } from '../../../components/fields/adornments';
import usePortalStyles from '../../../layouts/PortalLayout/usePortalStyles';
import useCommonStyles from '../../../useCommonStyles';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';

export type ResetPasswordFormProps = {
    token: string;
    next: () => void;
};

export type ResetPasswordFormValues = {
    password: string;
    confirmPassword: string;
};

const formValidator = validators.compose(
    validators.requiredString('password'),
    validators.requiredString('confirmPassword'),
    validators.passwordMatch('confirmPassword', 'password')
);

const initialValues: ResetPasswordFormValues = { password: '', confirmPassword: '' };

const ResetPasswordForm = ({ next, token }: ResetPasswordFormProps) => {
    const portalStyles = usePortalStyles();
    const commonStyles = useCommonStyles();
    const { t } = useTranslation(['resetPasswordPage']);

    const [reset] = useConsumeResetPasswordMutation();

    const onSubmit = useHandleError(
        async ({ password }: ResetPasswordFormValues) => {
            await reset({ variables: { token, password } });
            next();
        },
        [token, next]
    );

    const validate = useValidator(formValidator);

    return (
        <Grid xs={12} item>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                {({ isSubmitting }) => (
                    <Form>
                        <Grid spacing={2} container>
                            <Grid xs={12} item>
                                <OutlinedPasswordField
                                    className={portalStyles.resetPasswordField}
                                    color="secondary"
                                    name="password"
                                    placeholder={t('resetPasswordPage:fields.passwordPlaceholder')}
                                    startAdornment={<PasswordAdornment />}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <OutlinedPasswordField
                                    className={portalStyles.resetPasswordField}
                                    color="secondary"
                                    name="confirmPassword"
                                    placeholder={t('resetPasswordPage:fields.confirmPasswordPlaceholder')}
                                    startAdornment={<PasswordAdornment />}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.removeBoxShadow}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('resetPasswordPage:submitButton')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};

export default ResetPasswordForm;
