import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../Session';
import {
    useGetSaleTransactionsQuery,
    UserType,
    SaleTransactionStage,
    DownloadFileType,
    SalesTransactionAction,
    PreviewSaleTransactionDataFragment,
} from '../../api';
import ListPagination from '../../components/ListPagination';
import { useLoading } from '../../components/LoadingProvider';
import NoRecordsFound from '../../components/NoRecordsFound';
import { SaleTransactionsHeader, SaleTransactionsBody, FilterDialog, DownloadSaleListFn } from './components';
import FilterMenu from './components/FilterMenu';
import useSaleTransactionFilename from './hooks/useSaleTransactionFileName';

type ListContext = {
    search: string;
    page: number;
};

export type SaleTransactionsProps = {
    downloadSalesTransactionsFile: DownloadSaleListFn;
};

const pageSize = 9;

type TransactionBodyProps = {
    count: number;
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    pageSize: number;
    setActivePage: (page: number) => void;
};

const TransactionBody = ({ count, saleTransactions, page, pageSize, setActivePage }: TransactionBodyProps) => {
    if (count === 0) {
        return <NoRecordsFound />;
    }

    return (
        <>
            <SaleTransactionsBody saleTransactions={saleTransactions} hideAwardedBitAmount />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

const SaleTransactions = ({ downloadSalesTransactionsFile }: SaleTransactionsProps) => {
    const { t } = useTranslation(['common']);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [stage, setStage] = useState<'all' | SaleTransactionStage>('all');
    const [createdAt, setCreatedAt] = useState<Date | null>(null);
    const [targetHandoverDate, setTargetHandoverDate] = useState<Date | null>(null);
    const [awardedDealer, setAwardedDealer] = useState<string>('all');
    const currentUser = useCurrentUser();
    const { attach } = useLoading();

    const [{ page, search }, setListContext] = useState<ListContext>({ search: '', page: 0 });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const { data } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: {
                search,
                stages: stage === 'all' ? null : [stage],
                createdAt,
                handoverAt: targetHandoverDate,
                awardedDealer: awardedDealer === 'all' || awardedDealer === 'porschePreowned' ? null : awardedDealer,
                action: awardedDealer === 'porschePreowned' ? SalesTransactionAction.Purchased : null,
            },
            sort: {
                stagePriority: currentUser.type === UserType.Approver ? SaleTransactionStage.PendingApproval : null,
            },
        },
    });

    const setValues = useCallback(
        (salesStage, salesCreatedDate, targetHandoverDate, awardedDealer) => {
            setStage(salesStage);
            setCreatedAt(salesCreatedDate);
            setTargetHandoverDate(targetHandoverDate);
            setAwardedDealer(awardedDealer);
        },
        [setStage, setCreatedAt, setTargetHandoverDate, setAwardedDealer]
    );

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    const searchOnChange = useCallback(
        (searchValue: string) => {
            setListContext(state => ({ ...state, search: searchValue }));
        },
        [setListContext]
    );

    const count = data?.saleTransactions?.count || 0;
    const saleTransactions = data?.saleTransactions?.items || [];

    const getFileName = useSaleTransactionFilename();
    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile(
                    {
                        downloadFileType: type,
                        filter: {
                            search,
                            stages: stage === 'all' ? null : [stage],
                            createdAt,
                            handoverAt: targetHandoverDate,
                            awardedDealer: awardedDealer === 'all' ? null : awardedDealer,
                        },
                        sort: {
                            stagePriority:
                                currentUser.type === UserType.Approver ? SaleTransactionStage.PendingApproval : null,
                        },
                    },
                    getFileName({ type, isListing: true })
                );
            };

            attach(execute());
        },
        [
            attach,
            awardedDealer,
            createdAt,
            currentUser.type,
            downloadSalesTransactionsFile,
            getFileName,
            search,
            stage,
            targetHandoverDate,
        ]
    );

    const header = (
        <SaleTransactionsHeader
            downloadExcel={() => downloadFile(DownloadFileType.Excel)}
            downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
            search={search}
            searchOnChange={searchOnChange}
            setShowFilterDialog={setShowFilterDialog}
            setShowFilterMenu={setShowFilterMenu}
            title={t('saleTransactionsPage:list.title')}
        />
    );

    return (
        <>
            <FilterDialog
                awardedDealer={awardedDealer}
                createdAt={createdAt}
                open={showFilterDialog}
                setOpen={setShowFilterDialog}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            <FilterMenu
                awardedDealer={awardedDealer}
                createdAt={createdAt}
                open={showFilterMenu}
                setOpen={setShowFilterMenu}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            {header}
            <TransactionBody
                count={count}
                page={page}
                pageSize={pageSize}
                saleTransactions={saleTransactions}
                setActivePage={setActivePage}
            />
        </>
    );
};

export default SaleTransactions;
