import { Box, Grid, CardMedia, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useState } from 'react';
import { cardDefaultImage } from '../../../../server/schema/constants';
import { useCurrentUser } from '../../../Session';
import { FullSaleTransactionDataFragment, UserType, BiddingStatus, SaleTransactionStage } from '../../../api';
import TabPanel from '../../../components/TabPanel';
import usePublic from '../../../utilities/usePublic';
import DesktopMenuTabs from '../DesktopMenuTabs';
import MobileMenuTabs from '../MobileMenuTabs';
import BiddingLiveDuration from './components/BiddingLiveDuration';
import BiddingTab from './components/BiddingTab';
import TransactionTab from './components/TransactionTab';
import VehicleInfo from './components/VehicleInfo';
import VehicleTab from './components/VehicleTab';

export type ViewSaleTransactionBodyProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

export enum SaleTransactionTabValue {
    Transaction = 'transaction',
    Vehicle = 'vehicle',
    Bidding = 'bidding',
    Handover = 'handover',
}

const a11yProps = (index: string) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(8),
            },
        },
        body: {
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
        },
        carImage: {
            height: theme.spacing(30),
            borderRadius: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                borderRadius: theme.spacing(0),
            },
            backgroundColor: '#B3B3B3',
        },
        carImageContain: {
            backgroundSize: 'contain',
        },
    })
);

type TabPanelProps = {
    value: string;
    userType: UserType;
    saleTransaction: FullSaleTransactionDataFragment;
};

const DraftSaleTabPanel = ({ value, saleTransaction }: Omit<TabPanelProps, 'userType'>) => (
    <TabPanel index={SaleTransactionTabValue.Vehicle} value={value}>
        <VehicleTab saleTransaction={saleTransaction} />
    </TabPanel>
);

const NonDraftSaleTabPanel = ({ value, userType, saleTransaction }: TabPanelProps) => (
    <>
        {userType !== UserType.Dealer && (
            <TabPanel index={SaleTransactionTabValue.Transaction} value={value}>
                <TransactionTab saleTransaction={saleTransaction} />
            </TabPanel>
        )}
        <TabPanel index={SaleTransactionTabValue.Vehicle} value={value}>
            <VehicleTab saleTransaction={saleTransaction} />
        </TabPanel>
        <TabPanel index={SaleTransactionTabValue.Bidding} value={value}>
            <BiddingTab saleTransaction={saleTransaction} />
        </TabPanel>
    </>
);

const SaleTransactionTabPanel = ({ userType, value, saleTransaction }: TabPanelProps) => {
    switch (saleTransaction.stage) {
        case SaleTransactionStage.Drafted:
            return <DraftSaleTabPanel saleTransaction={saleTransaction} value={value} />;

        default:
            return <NonDraftSaleTabPanel saleTransaction={saleTransaction} userType={userType} value={value} />;
    }
};

const getInitialTabValue = (userType: UserType, stage: SaleTransactionStage) => {
    if (stage === SaleTransactionStage.Drafted || userType === UserType.Dealer) {
        return SaleTransactionTabValue.Vehicle;
    }

    return SaleTransactionTabValue.Transaction;
};

const ViewSaleTransactionBody = ({ saleTransaction }: ViewSaleTransactionBodyProps) => {
    const styles = useStyles();
    const currentUser = useCurrentUser();
    const [value, setValue] = useState<SaleTransactionTabValue>(
        getInitialTabValue(currentUser.type, saleTransaction.stage)
    );

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultImage = usePublic(cardDefaultImage);

    const showBiddingLiveDuration = [BiddingStatus.Upcoming, BiddingStatus.Current].includes(
        saleTransaction.latestBiddingSession?.status
    );

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0}>
                {isSmall && (
                    <Box position="relative">
                        <CardMedia
                            className={cs([
                                styles.carImage,
                                !saleTransaction.frontPagePhoto?.signedUrl && styles.carImageContain,
                            ])}
                            image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                        />
                        {showBiddingLiveDuration && <BiddingLiveDuration transaction={saleTransaction} />}
                    </Box>
                )}
                <Box className={styles.body} pl={isSmall ? 1 : 0} pr={isSmall ? 1 : 0}>
                    <Box mt={showBiddingLiveDuration ? 6 : 0}>
                        <Grid spacing={2} container>
                            <Grid lg={5} md={5} sm={12} xs={12} item>
                                <Box pl={isSmall ? 2 : 0} pr={isSmall ? 2 : 0}>
                                    <VehicleInfo saleTransaction={saleTransaction} />
                                </Box>
                            </Grid>
                            <Grid lg={7} md={7} sm={12} xs={12} item>
                                {!isSmall && (
                                    <DesktopMenuTabs
                                        a11yProps={a11yProps}
                                        setValue={setValue}
                                        stage={saleTransaction.stage}
                                        value={value}
                                    />
                                )}
                                <Box pl={isSmall ? 0.5 : 0} pr={isSmall ? 0.5 : 0}>
                                    <SaleTransactionTabPanel
                                        saleTransaction={saleTransaction}
                                        userType={currentUser.type}
                                        value={value}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {isSmall && (
                <MobileMenuTabs a11yProps={a11yProps} setValue={setValue} stage={saleTransaction.stage} value={value} />
            )}
        </>
    );
};

export default ViewSaleTransactionBody;
