import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { FullSaleTransactionDataFragment, SaleTransactionStage } from '../../../api';
import TabPanel from '../../../components/TabPanel';
import DesktopMenuTabs from '../DesktopMenuTabs';
import MobileMenuTabs from '../MobileMenuTabs';
import { SaleTransactionTabValue } from '../ViewSaleTransaction/ViewSaleTransactionBody';
import { EditSaleTransactionFormValues } from './EditSaleTransactionForm';
import { useEditSaleTransactionState } from './EditSalesTransactionStateProvider';
import { HandoverTab, SessionDetailsTab, TransactionTab, VehicleTab } from './components';

const a11yProps = (index: SaleTransactionTabValue) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
            },
        },
    })
);

export type EditSaleTransactionBodyProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    validate: (values: any) => object;
};

const EditSaleTransactionBody = ({ saleTransaction, validate }: EditSaleTransactionBodyProps) => {
    const styles = useStyles();
    const [value, setValue] = useState<SaleTransactionTabValue>(
        saleTransaction.stage === SaleTransactionStage.Drafted
            ? SaleTransactionTabValue.Vehicle
            : SaleTransactionTabValue.Transaction
    );

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        state: { formValues },
    } = useEditSaleTransactionState();

    const showImport =
        !saleTransaction.vehicle.imported &&
        saleTransaction.stage === SaleTransactionStage.Drafted &&
        isEmpty(formValues);

    const { setValues, setTouched } = useFormikContext<EditSaleTransactionFormValues>();

    useEffect(() => {
        if (formValues) {
            setValues(formValues);
            setTouched(validate(formValues), true);
        }
    }, [formValues, setValues, setTouched, validate]);

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0} pl={isSmall ? 1 : 0} pr={isSmall ? 1 : 0}>
                <Grid spacing={2} container>
                    <Grid xs={12} item>
                        {!isSmall && (
                            <DesktopMenuTabs
                                a11yProps={a11yProps}
                                setValue={setValue}
                                stage={saleTransaction.stage}
                                value={value}
                            />
                        )}
                        <Box pl={isSmall ? 0.5 : 0} pr={isSmall ? 0.5 : 0}>
                            <TabPanel index="transaction" value={value}>
                                <TransactionTab />
                            </TabPanel>
                            <TabPanel index="vehicle" value={value}>
                                <VehicleTab showImport={showImport} />
                            </TabPanel>
                            <TabPanel index="bidding" value={value}>
                                <SessionDetailsTab saleTransaction={saleTransaction} />
                            </TabPanel>
                            <TabPanel index="handover" value={value}>
                                <HandoverTab saleTransaction={saleTransaction} />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {isSmall && (
                <MobileMenuTabs a11yProps={a11yProps} setValue={setValue} stage={saleTransaction.stage} value={value} />
            )}
        </>
    );
};

export default EditSaleTransactionBody;
