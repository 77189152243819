import { UserType } from '../../../api';

const getMenuListItems = (role: UserType) => {
    const salesTransactionsAssets = () => {
        switch (role) {
            case UserType.SaleConsultant:
                return { icon: 'Group_6028.svg', text: 'myBids' };

            default:
                return { icon: 'Group_6029.svg', text: 'salesTransactionRecords' };
        }
    };

    const menuListItems = [
        {
            users: [UserType.Admin],
            icon: 'dashboard-24px.svg',
            text: 'dashboard',
            path: '/',
        },
        {
            users: [UserType.Admin],
            icon: 'account_circle-24px.svg',
            text: 'userDatabase',
            path: '/users',
        },
        {
            users: [UserType.Admin],
            icon: 'gpp_good-24px.svg',
            text: 'approvals',
            path: '/approvals',
        },
        {
            users: [UserType.Dealer],
            icon: 'Group_6028.svg',
            text: 'myBids',
            path: '/myBids',
        },
        {
            users: [UserType.Dealer],
            icon: 'Group_6028.svg',
            text: 'allBiddingSessions',
            path: '/allBiddingSessions',
        },
        {
            users: [UserType.Approver],
            icon: 'Group_6028.svg',
            text: 'approvals',
            path: '/transactionApprovals',
        },
        {
            users: [UserType.Approver],
            icon: 'Group_6029.svg',
            text: 'biddingSessions',
            path: '/approverBiddingSessions',
        },
        {
            users: [UserType.ValuationTeam],
            icon: 'Group_6028.svg',
            text: 'myTransactions',
            path: '/myTransactions',
        },
        {
            users: [UserType.ValuationTeam],
            icon: 'Group_6028.svg',
            text: 'purchaseTransactionRecords',
            path: '/purchaseTransactions',
        },
        {
            users: [UserType.Admin, UserType.SaleConsultant, UserType.ValuationTeam],
            icon: salesTransactionsAssets().icon,
            text: salesTransactionsAssets().text,
            path: '/salesTransactions',
        },
        {
            users: [UserType.Admin],
            icon: 'circle_notifications-24px.svg',
            text: 'notificationsLog',
            path: '/notifications',
        },
        {
            users: [UserType.Admin],
            icon: 'history.svg',
            text: 'activityHistory',
            path: '/activityHistory',
        },
    ];

    return menuListItems.filter(menu => menu.users.includes(role));
};

export default getMenuListItems;
