import { Box } from '@material-ui/core';
import React from 'react';
import usePublic from '../../../../utilities/usePublic';
import useBasicStyles from '../../useBasicStyles';

export type PanelListItem = {
    icon: string;
    text: string;
    to: string;
};

export type DrawerHeaderProps = {
    open: boolean;
};

const DrawerHeader = ({ open }: DrawerHeaderProps) => {
    const classes = useBasicStyles({ drawerOpened: open });

    const drawerOpenedLogo = usePublic('assets/images/Audi_Rings_wh-RGB.png');
    const drawerMinimizedLogo = usePublic('assets/images/Audi_Rings_wh-RGB-square.png');

    return (
        <Box style={{ boxShadow: '0px 0px 20px #00000029' }}>
            <Box className={classes.logoWrapper}>
                <img alt="logo" className={classes.logo} src={open ? drawerOpenedLogo : drawerMinimizedLogo} />
            </Box>
        </Box>
    );
};

export default DrawerHeader;
