import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useCurrentUser } from '../../../Session';
import {
    SaleTransactionStage,
    FullSaleTransactionDataFragment,
    UpdateSaleTransactionMutationVariables,
    UserType,
    useUpdateSaleTransactionMutation,
    NameOnlyFileDataFragment,
    useUploadSalesTransactionHandoverAttachmentMutation,
    useRemoveSalesTransactionHandoverAttachmentMutation,
    useUploadVsaAttachmentMutation,
    useRemoveVsaAttachmentMutation,
    useUploadSalesTransactionPhotoMutation,
    useRemoveSalesTransactionPhotoMutation,
    useUpdateSalesTransactionFrontPagePhotoMutation,
    useUpdateBiddingMutation,
    useAddSalesTransactionVehicleDiagramCommentMutation,
    useRemoveSalesTransactionVehicleDiagramCommentMutation,
} from '../../../api';
import FileViewerProvider from '../../../components/FileViewerProvider';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import { diffUploads } from '../../../utilities/file';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import { VehicleDiagramComment } from '../components/VehicleDiagram';
import useVehicleValidation from '../hooks/useVehicleValidation';
import EditSaleTransactionBody from './EditSaleTransactionBody';
import EditSaleTransactionHeader from './EditSaleTransactionHeader';
import { Page, useEditSaleTransactionState } from './EditSalesTransactionStateProvider';

export type EditSaleTransactionProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    goToView: () => void;
};

export type EditSaleTransactionFormValues = {
    handover: {
        targetHandoverDateTime: Date;
        handoverLocationField: { main: string; other?: string };
        attachments: (NameOnlyFileDataFragment | File)[];
        vsaAttachments: (NameOnlyFileDataFragment | File)[];
    };
    photos: (NameOnlyFileDataFragment | File)[];
    frontPagePhoto?: NameOnlyFileDataFragment | File;
    biddingSession?: { name: string; notes: string };
    comments?: VehicleDiagramComment[];
} & UpdateSaleTransactionMutationVariables['fields'];

const computePayload = (values: EditSaleTransactionFormValues): UpdateSaleTransactionMutationVariables['fields'] => {
    const { frontPagePhoto, photos, handover, biddingSession, comments, ...fields } = values;

    const {
        handoverLocationField,
        handoverLocation,
        targetHandoverDateTime,
        attachments,
        vsaAttachments,
        personResponsible,
        ...handoverFields
    } = handover ?? {};

    return {
        handover: handover && {
            targetHandoverDateTime,
            handoverLocation: handoverLocationField?.other || handoverLocationField?.main,
            personResponsible: personResponsible || null,
            ...handoverFields,
        },
        ...fields,
    };
};

const EditSaleTransactionForm = ({ saleTransaction, goToView }: EditSaleTransactionProps) => {
    const [updateSaleTransaction] = useUpdateSaleTransactionMutation();
    const [uploadHandoverAttachmentMutation] = useUploadSalesTransactionHandoverAttachmentMutation();
    const [removeHandoverAttachmentMutation] = useRemoveSalesTransactionHandoverAttachmentMutation();
    const [removeSalesTransactionPhotoMutation] = useRemoveSalesTransactionPhotoMutation();
    const [uploadSalesTransactionPhotoMutation] = useUploadSalesTransactionPhotoMutation();
    const [uploadVsaAttachmentMutation] = useUploadVsaAttachmentMutation();
    const [removeVsaAttachmentMutation] = useRemoveVsaAttachmentMutation();
    const [updateSalesTransactionFrontPagePhotoMutation] = useUpdateSalesTransactionFrontPagePhotoMutation();
    const [updateBiddingMutation] = useUpdateBiddingMutation();
    const [addSalesTransactionVehicleDiagram] = useAddSalesTransactionVehicleDiagramCommentMutation();
    const [removeSalesTransactionVehicleDiagram] = useRemoveSalesTransactionVehicleDiagramCommentMutation();

    const currentUser = useCurrentUser();

    const basicStyles = useBasicStyle();

    const initialFrontPagePhotoId = saleTransaction.frontPagePhotoSourceId;
    const initialPhotos = saleTransaction.photos;
    const initialAttachments = saleTransaction.handover?.attachments ?? [];
    const initialVehicleDiagramComments = saleTransaction.vehicleDiagramComments;
    const initialVsaAttachments = saleTransaction.handover?.vsaAttachment
        ? [saleTransaction.handover.vsaAttachment]
        : [];

    const {
        state: { initialValues, formValues },
    } = useEditSaleTransactionState();

    const onSubmit = useHandleError(
        async (values: EditSaleTransactionFormValues) => {
            const photosDiff = diffUploads(values.photos, initialPhotos);
            const attachmentsDiff = diffUploads(values.handover?.attachments, initialAttachments);
            const vsaAttachmentsDiff = diffUploads(values.handover?.vsaAttachments, initialVsaAttachments);
            const fields = computePayload(values);
            const { frontPagePhoto, biddingSession, comments } = values;

            await Promise.all([
                ...photosDiff.removedUploads.map(async file => {
                    await removeSalesTransactionPhotoMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...attachmentsDiff.removedUploads.map(async file => {
                    await removeHandoverAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...vsaAttachmentsDiff.removedUploads.map(async file => {
                    await removeVsaAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...photosDiff.newUploads.map(async file => {
                    await uploadSalesTransactionPhotoMutation({
                        variables: {
                            salesTransactionId: saleTransaction.id,
                            file,
                            isFrontPagePhoto: frontPagePhoto === file,
                        },
                    });
                }),
                ...attachmentsDiff.newUploads.map(async file => {
                    await uploadHandoverAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, file },
                    });
                }),
                ...vsaAttachmentsDiff.newUploads.map(async file => {
                    await uploadVsaAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, file },
                    });
                }),
            ]);

            if (!(frontPagePhoto instanceof File) && frontPagePhoto?.id !== initialFrontPagePhotoId) {
                await updateSalesTransactionFrontPagePhotoMutation({
                    variables: { salesTransactionId: saleTransaction.id, frontPagePhotoId: frontPagePhoto?.id },
                });
            }

            if (
                saleTransaction.stage !== SaleTransactionStage.New &&
                saleTransaction.stage !== SaleTransactionStage.Completed &&
                saleTransaction.stage !== SaleTransactionStage.Closed &&
                saleTransaction.stage !== SaleTransactionStage.Cancelled
            ) {
                const { latestBiddingSession } = saleTransaction;

                if (latestBiddingSession) {
                    await updateBiddingMutation({
                        variables: {
                            biddingId: latestBiddingSession.id,
                            fields: {
                                sessionName: biddingSession.name,
                                notes: biddingSession.notes,
                            },
                        },
                    });
                }
            }

            await updateSaleTransaction({
                variables: { transactionId: saleTransaction.id, fields },
            });

            // Create / delete diagram comments
            const commentIds = comments.map(comment => comment.id);
            const removedArray = initialVehicleDiagramComments.filter(item => !commentIds.includes(item.id));

            for (const comment of removedArray) {
                // eslint-disable-next-line no-await-in-loop
                await removeSalesTransactionVehicleDiagram({
                    variables: {
                        salesTransactionId: saleTransaction.id,
                        vehicleDiagramCommentID: comment.id,
                    },
                });
            }

            const newArray = comments.filter(item => !item.id);
            for (const comment of newArray) {
                // eslint-disable-next-line no-await-in-loop
                await addSalesTransactionVehicleDiagram({
                    variables: {
                        fields: comment,
                        salesTransactionId: saleTransaction.id,
                    },
                });
            }

            goToView();
        },
        [saleTransaction, goToView, initialFrontPagePhotoId, initialPhotos]
    );

    const basicOnly =
        !saleTransaction.vehicle.imported &&
        saleTransaction.stage === SaleTransactionStage.Drafted &&
        isEmpty(formValues);

    const vehicleValidation = useVehicleValidation(basicOnly);

    const formValidator = useMemo(
        () =>
            validators.compose(
                // validation for admin
                validators.only(
                    () => currentUser.type === UserType.Admin,
                    validators.compose(
                        vehicleValidation,
                        validators.requiredString('customerAssessment'),
                        validators.only(() => !basicOnly, validators.requiredDate('handover.targetHandoverDateTime'))
                    )
                ),

                validators.only(
                    () => saleTransaction.stage === SaleTransactionStage.ForAwarding,
                    validators.compose(
                        validators.only(
                            () =>
                                currentUser.type === UserType.Admin ||
                                currentUser.type === UserType.ValuationTeam ||
                                currentUser.type === UserType.Approver,
                            validators.compose(
                                validators.requiredString('handover.personResponsible'),
                                validators.requiredDate('handover.targetHandoverDateTime')
                            )
                        ),

                        validators.only(
                            () => currentUser.type === UserType.Admin || currentUser.type === UserType.Approver,
                            validators.compose(validators.requiredString('handover.vehicleSalesAgreementNo'))
                        )
                    )
                )
            ),
        [currentUser, saleTransaction, vehicleValidation, basicOnly]
    );

    const validate = useValidator(formValidator);

    if (!initialValues) {
        return null;
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <FileViewerProvider>
                        <EditSaleTransactionHeader currentPage={Page.VehicleForm} goToView={goToView} />
                        <Box className={basicStyles.mainDesktop}>
                            <EditSaleTransactionBody saleTransaction={saleTransaction} validate={validate} />
                        </Box>
                    </FileViewerProvider>
                </Form>
            )}
        </Formik>
    );
};

export default EditSaleTransactionForm;
