import { Button, FormControl, FormHelperText, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { Formik, Form } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, useBroadcastSalesTransactionMutation } from '../../../../api';
import Dialog from '../../../../components/Dialog';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import useCommonStyles from '../../../../useCommonStyles';
import { useHandleError } from '../../../../utilities/handleErrors';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            padding: '0px',
        },
        formControl: {
            marginTop: '40px',
        },
        dialogContent: {
            minHeight: '100px',
        },
        button: {
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    })
);

export type ActionBroadcastProps = {
    transaction?: FullSaleTransactionDataFragment;
};

const MAXCHAR = 160;

const formValidator = validators.compose(validators.requiredString('message'), validators.maxChar('message', MAXCHAR));

const ActionBroadcast = ({ transaction }: ActionBroadcastProps) => {
    const [isShown, setShown] = useState(false);
    const commonStyles = useCommonStyles();
    const { t } = useTranslation(['saleTransactionsPage']);

    const [broadcastSalesTransaction] = useBroadcastSalesTransactionMutation();

    const styles = useStyles();

    const onSubmit = useHandleError(
        async ({ message }) => {
            await broadcastSalesTransaction({
                variables: {
                    message,
                    salesTransactionId: transaction.id,
                },
            });

            setShown(false);
        },
        [broadcastSalesTransaction, transaction]
    );

    const onCancel = useCallback(() => setShown(false), []);

    const validate = useValidator(formValidator);

    const initialValues = useMemo(
        () => ({
            message: transaction?.vehicle?.number ? `[${transaction.vehicle.number}]` : '',
        }),
        [transaction]
    );

    return (
        <>
            <Button
                className={cs(
                    commonStyles.removeBoxShadow,
                    commonStyles.defaultBorderRadius,
                    styles.button,
                    commonStyles.primaryButtonBorder
                )}
                color="primary"
                onClick={() => setShown(old => !old)}
                size="large"
                type="button"
                variant="contained"
            >
                {t('saleTransactionsPage:sessionDetails.broadcast.action')}
            </Button>
            <Dialog
                dialogContentClassName={styles.dialogContent}
                maxWidth="sm"
                onCloseClick={onCancel}
                open={isShown}
                title={t('saleTransactionsPage:sessionDetails.broadcast.title')}
                fullWidth
            >
                <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                    {({ isSubmitting }) => (
                        <Form className={styles.container}>
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    <OutlinedInputField
                                        color="secondary"
                                        helperText={value => (
                                            <FormHelperText
                                                style={{ marginLeft: 'auto' }}
                                            >{`${value.length}/${MAXCHAR}`}</FormHelperText>
                                        )}
                                        label={t('saleTransactionsPage:sessionDetails.broadcast.message')}
                                        minRows={2}
                                        name="message"
                                        placeholder={t(
                                            'saleTransactionsPage:sessionDetails.broadcast.messagePlaceholder'
                                        )}
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                            <Grid className={styles.formControl} spacing={2} container>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <Button
                                            className={commonStyles.primaryButtonBorder}
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            {t('common:cancel')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <Button
                                            color="secondary"
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                        >
                                            {t('common:send')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};

export default ActionBroadcast;
