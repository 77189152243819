import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { AddTransactionBulk } from './components';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
                padding: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
        },
    })
);

/**
 * @deprecated This is unused.
 * TODO This should be removed after clarification with team.
 */
const CreateSaleTransactionBody = () => {
    const styles = useStyles();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={styles.root} mb={isSmall ? 10 : 0}>
            <Grid container>
                <Grid xs={12} item>
                    <AddTransactionBulk />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CreateSaleTransactionBody;
