import dayjs from 'dayjs';
import { isEmpty, split, trim } from 'lodash/fp';
import sanitizeHtml from 'sanitize-html';
import { SaleTransactionVehicleFields } from '../../../../api';

const parseNumber = (input: string, withDecimal = false) => {
    const purified = input.replace(/[^0-9.-]+/g, '');
    const parsed = withDecimal ? parseFloat(purified) : parseInt(purified, 10);

    return Number.isNaN(parsed) ? null : parsed;
};

const parseDate = (input: string) => {
    const value = dayjs(input, ['DD-MMM-YY', 'DD-MMM-YYYY', 'DD MMM YY', 'DD MMM YYYY'], true);

    return value.isValid() ? value.toDate() : null;
};

const contentToFieldName: Record<string, keyof SaleTransactionVehicleFields> = {
    'Owner ID': 'ownerId',
    'Vehicle No.': 'number',
    'Vehicle Make': 'make',
    'Vehicle Model': 'model',
    'Primary Colour': 'primaryColour',
    'Secondary Colour': 'secondaryColour',
    'Engine No.': 'engineNumber',
    'Chassis No.': 'chassisNumber',
    'COE Category': 'coeCategory',
    'OPC Cash Rebate Eligibility': 'opcCashRebateEligibility',
    'LTA Message': 'ltaMessage',

    // boolean
    'Vehicle to be Exported': 'beExported',
    'PARF Eligibility': 'parfEligibility',

    // date
    'Intended Deregistration Date': 'intendedDeregistrationDate',
    'PARF Eligibility Expiry Date': 'parfEligibilityDate',
    'COE Expiry Date': 'coeExpiryDate',
    'OPC Cash Rebate Eligibility Expiry Date': 'opcCashRebateEligibilityExpiryDate',
    'Original Registration Date': 'originalRegistrationDate',
    'First Registration Date': 'firstRegistrationDate',

    // int
    'Manufacturing Year': 'manufacturingYear',
    'Transfer Count': 'transferCount',
    'COE Period(Years)': 'coePeriodYear',

    // sanitizeHtml
    'Maximum Power Output': 'maximumPowerOutput',

    // float
    'Open Market Value': 'openMarketValue',
    'Actual ARF Paid': 'actualARFPaid',
    'PARF Rebate Amount': 'parfRebateAmount',
    'QP Paid': 'qpPaid',
    'COE Rebate Amount': 'coeRebateAmount',
    'OPC Cash Rebate Amount': 'opcCashRebateAmount',
    'PQP Paid': 'pqpPaid',
    'Total Rebate Amount': 'totalRebateAmount',
};

const parse = (raw: string | null | undefined) => {
    if (isEmpty(raw)) {
        return null;
    }

    const lines = split(/\r?\n/, raw).filter(Boolean);

    const vehicle: Partial<SaleTransactionVehicleFields> = {};

    let fieldName: keyof SaleTransactionVehicleFields | undefined;

    for (const line of lines) {
        if (fieldName) {
            switch (fieldName) {
                case 'ownerId':
                case 'number':
                case 'make':
                case 'model':
                case 'primaryColour':
                case 'secondaryColour':
                case 'engineNumber':
                case 'chassisNumber':
                case 'coeCategory':
                case 'opcCashRebateEligibility':
                case 'ltaMessage':
                    vehicle[fieldName] = trim(line);
                    break;

                case 'beExported':
                case 'parfEligibility':
                    vehicle[fieldName] = trim(line).toLowerCase().includes('yes');
                    break;

                case 'intendedDeregistrationDate':
                case 'originalRegistrationDate':
                case 'firstRegistrationDate':
                case 'parfEligibilityDate':
                case 'coeExpiryDate':
                case 'opcCashRebateEligibilityExpiryDate':
                    vehicle[fieldName] = parseDate(trim(line));
                    break;

                case 'manufacturingYear':
                case 'transferCount':
                case 'coePeriodYear':
                    vehicle[fieldName] = parseNumber(trim(line));
                    break;

                case 'maximumPowerOutput':
                    vehicle[fieldName] = sanitizeHtml(trim(line)).replace(/[\t\n]/g, '');
                    break;

                case 'openMarketValue':
                case 'actualARFPaid':
                case 'parfRebateAmount':
                case 'qpPaid':
                case 'coeRebateAmount':
                case 'opcCashRebateAmount':
                case 'pqpPaid':
                case 'totalRebateAmount':
                    vehicle[fieldName] = parseNumber(trim(line), true);
                    break;

                default:
                    break;
            }

            fieldName = undefined;
            continue;
        }

        const parsedFieldName = contentToFieldName[trim(line)];
        if (parsedFieldName) {
            fieldName = parsedFieldName;
        }
    }

    return isEmpty(vehicle) ? null : vehicle;
};

export default parse;
