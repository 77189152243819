import { Card, CardContent, Grid, Typography, Box, CardMedia } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import usePublic from '../../../utilities/usePublic';

export type TransactionProps = {
    title: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'relative',
            minHeight: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#F2F2F2',
        },
        cardContent: {
            zIndex: 10,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        backgroundImage: {
            position: 'absolute',
            top: 0,
            right: 0,
            height: '200px',
            width: '280px',
        },
        title: {
            marginBottom: '17px',
            fontWeight: 700,
        },
    })
);

const Transaction = ({ title, children }: React.PropsWithChildren<TransactionProps>) => {
    const styles = useStyles();

    const image = usePublic('assets/menuIcons/desktopCardBackground.svg');

    return (
        <Card className={styles.card} elevation={0}>
            <CardContent className={styles.cardContent}>
                <Grid xs={12} item>
                    <Typography className={styles.title}>{title}</Typography>
                    <Box>{children}</Box>
                </Grid>
            </CardContent>
            <CardMedia className={styles.backgroundImage} image={image} />
        </Card>
    );
};

export default Transaction;
