import { Dialog, Slide, Transitions, Box, Typography, Button, DialogTitle, IconButton, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SaleTransactionStage, useGetAwardedDealersQuery } from '../../../../api';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import useSalesTransactionStateFilterOptions from '../../../../utilities/useSalesTransactionStateFilterOptions';
import useFilterMenuStyles from '../../../SaleTransactions/components/useFilterMenuStyles';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (stage, createdAt, targetHandoverDate, awardedDealer) => void;
    stage: 'all' | SaleTransactionStage;
    createdAt: Date | null;
    targetHandoverDate: Date | null;
    awardedDealer: string;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterMenu = ({
    open,
    setOpen,
    setValues,
    stage,
    createdAt,
    targetHandoverDate,
    awardedDealer,
}: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterMenuStyles();

    const statuses = useSalesTransactionStateFilterOptions();

    const onSubmit = useCallback(
        (values: {
            stage: string;
            createdAt: Date | string;
            targetHandoverDate: Date | string;
            awardedDealer: string;
        }) => {
            const { stage, createdAt, targetHandoverDate, awardedDealer } = values;

            setValues(stage, createdAt, targetHandoverDate, awardedDealer);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    const { data: awardedDealersData } = useGetAwardedDealersQuery({
        fetchPolicy: 'cache-and-network',
    });

    const awardedDealerOptions = useMemo(
        () => [
            { value: 'all', label: t('saleTransactionsPage:filter.all') },
            ...(awardedDealersData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        ],
        [awardedDealersData, t]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open}>
            <DialogTitle className={styles.dialogTitle}>
                <Typography color="secondary">{t('saleTransactionsPage:filterBy')}</Typography>

                <IconButton className={styles.closeButton} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik initialValues={{ stage, createdAt, targetHandoverDate, awardedDealer }} onSubmit={onSubmit}>
                {({ setFieldValue }) => (
                    <Form>
                        <Box className={styles.content} display="flex" flexDirection="column">
                            <Box mt={3} width="100%">
                                <OutlinedSelectField
                                    color="secondary"
                                    inputProps={{
                                        classes: {
                                            icon: commonStyles.fillSecondary,
                                        },
                                    }}
                                    label={t('saleTransactionsPage:state')}
                                    name="stage"
                                    options={statuses}
                                />
                            </Box>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('saleTransactionsPage:filter.createdAt')}
                                    name="createdAt"
                                    placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('createdAt', null)}
                                underline="none"
                            >
                                {t('saleTransactionsPage:button.resetDate')}
                            </Link>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('saleTransactionsPage:filter.targetHandoverDate')}
                                    name="targetHandoverDate"
                                    placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('targetHandoverDate', null)}
                                underline="none"
                            >
                                {t('saleTransactionsPage:button.resetDate')}
                            </Link>
                            <Box mt={3} width="100%">
                                <OutlinedSelectField
                                    color="secondary"
                                    inputProps={{
                                        classes: {
                                            icon: commonStyles.fillSecondary,
                                        },
                                    }}
                                    label={t('saleTransactionsPage:filter.awardedDealer')}
                                    name="awardedDealer"
                                    options={awardedDealerOptions}
                                />
                            </Box>
                        </Box>
                        <Box className={styles.buttonContainer}>
                            <Button
                                className={styles.applyButton}
                                color="secondary"
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {t('saleTransactionsPage:filter.applyFilters')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterMenu;
