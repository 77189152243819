import { Button, Grid, FormControl, useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    UserType,
    FullSaleTransactionDataFragment,
    SaleTransactionStage,
    useUpdateSaleTransactionToForHandoverMutation,
    useUpdateSaleTransactionToPendingApprovalMutation,
    useUpdateSaleTransactionToCompletedMutation,
    SalesTransactionAction,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import useCommonStyles from '../../../../useCommonStyles';
import ActionBroadcast from './ActionBroadcast';
import { useCancelFormatter } from './ActionCancelTransaction';
import ActionCloseTransaction from './ActionCloseTransaction';
import BiddingBlock from './BiddingBlock';
import ErrorDialog from './ErrorDialog';
import PastBids from './PastBids';
import TopBids from './TopBids';

export type BiddingTabTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        changeStageGridItem: {
            [theme.breakpoints.up('sm')]: {
                order: 1,
            },
        },
        closeTransactionLabel: {
            color: '#A5A5A5',
        },

        buttonContainer: {
            [theme.breakpoints.down('md')]: {
                width: '100%',
                flexDirection: 'column',
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
    })
);

const BiddingTab = ({ saleTransaction }: BiddingTabTabProps) => {
    const [validForApprovalFields, setValidForApprovalFields] = useState<string[] | null>(null);
    const [selectAwardeeRequireFields, setSelectAwardeeRequireFields] = useState<string[] | null>(null);

    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const currentUser = useCurrentUser();
    const styles = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const useCardDesignForAccordion = !isSmall;

    const variables = { transactionId: saleTransaction.id };
    const [updateToHandover] = useUpdateSaleTransactionToForHandoverMutation({ variables });
    const [updateToPendingApproval] = useUpdateSaleTransactionToPendingApprovalMutation({ variables });
    const [updateToCompleted] = useUpdateSaleTransactionToCompletedMutation({ variables });

    const onUpdateToHandover = useCallback(() => updateToHandover(), [updateToHandover]);

    const onUpdateToCompleted = useCallback(() => updateToCompleted(), [updateToCompleted]);

    const onUpdateToPendingApproval = useCallback(() => {
        if (saleTransaction.handover.vsaAttachment) {
            updateToPendingApproval();
            setValidForApprovalFields(null);
        } else {
            setValidForApprovalFields(t('saleTransactionsPage:handover.vsaAttachment'));
        }
    }, [saleTransaction.handover.vsaAttachment, t, updateToPendingApproval]);

    const { latestBiddingSession, latestBiddingSessionEntry } = saleTransaction;

    const lastBid = latestBiddingSessionEntry?.lastUserBid?.amount || null;

    const isPurschedByPorschePreowned = saleTransaction.action === SalesTransactionAction.Purchased;
    const isCancelled = saleTransaction.stage === SaleTransactionStage.Cancelled;

    const showTopBids = ![SaleTransactionStage.PendingApproval].includes(saleTransaction.stage);

    const isNotDealer = currentUser.type !== UserType.Dealer;
    const { lastActivity } = saleTransaction;

    const { formatReasonWithMessage } = useCancelFormatter();

    return (
        <Grid spacing={2} container>
            {showTopBids && (
                <TopBids saleTransaction={saleTransaction} useCardDesignForAccordion={useCardDesignForAccordion} />
            )}
            {isCancelled && isNotDealer && (
                <Grid xs={12} item>
                    <Accordion
                        title={t('saleTransactionsPage:sessionDetails.cancel.title')}
                        useCardDesign={useCardDesignForAccordion}
                        defaultExpanded
                    >
                        <Typography className={commonStyles.fontWeightLight}>
                            {lastActivity.__typename === 'SaleCancelActivity'
                                ? formatReasonWithMessage(lastActivity.reason, lastActivity.reasonMessage)
                                : ''}
                        </Typography>
                    </Accordion>
                </Grid>
            )}
            {!isPurschedByPorschePreowned && (
                <Grid xs={12} item>
                    <Accordion
                        title={t('saleTransactionsPage:sessionDetails.title')}
                        useCardDesign={useCardDesignForAccordion}
                        defaultExpanded
                    >
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={
                                        latestBiddingSession
                                            ? t('common:formats.longDateTime', {
                                                  date: new Date(saleTransaction.latestBiddingSession.startPeriod),
                                              })
                                            : t('common:blankValue')
                                    }
                                    label={t('saleTransactionsPage:sessionDetails.bidOpeningOn')}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={
                                        latestBiddingSession
                                            ? t('common:formats.longDateTime', {
                                                  date: new Date(saleTransaction.latestBiddingSession.endPeriod),
                                              })
                                            : t('common:blankValue')
                                    }
                                    label={t('saleTransactionsPage:sessionDetails.bidClosingDate')}
                                />
                            </Grid>
                            {currentUser.type === UserType.Dealer && lastBid && (
                                <Grid xs={12} item>
                                    <ContentWithLabel
                                        content={
                                            lastBid
                                                ? `${t('common:currency')}${lastBid?.toLocaleString()}`
                                                : t('common:blankValue')
                                        }
                                        label={t('saleTransactionsPage:bidDrawer.lastBid')}
                                    />
                                </Grid>
                            )}
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={
                                        !saleTransaction.latestBiddingSession?.notes
                                            ? t('common:blankValue')
                                            : saleTransaction.latestBiddingSession.notes
                                    }
                                    display="block"
                                    label={t('saleTransactionsPage:sessionDetails.bidNotes')}
                                />
                            </Grid>
                        </Grid>
                    </Accordion>
                </Grid>
            )}
            {showTopBids && <BiddingBlock transaction={saleTransaction} />}
            {[UserType.Admin, UserType.Approver, UserType.SaleConsultant].includes(currentUser.type) && (
                <>
                    <Grid xs={12} item>
                        <Grid className={styles.buttonContainer} spacing={1} container>
                            <Grid item>
                                <ActionBroadcast transaction={saleTransaction} />
                            </Grid>
                            {[SaleTransactionStage.BiddingOpen, SaleTransactionStage.UpcomingBidding].includes(
                                saleTransaction.stage
                            ) && (
                                <Grid item>
                                    <ActionCloseTransaction saleTransaction={saleTransaction} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <ErrorDialog
                        fields={selectAwardeeRequireFields}
                        onClose={() => setSelectAwardeeRequireFields(null)}
                    />
                </>
            )}
            {isNotDealer && (
                <Grid xs={12} item>
                    <PastBids saleTransaction={saleTransaction} />
                </Grid>
            )}
            {currentUser.type === UserType.ValuationTeam && (
                <>
                    {saleTransaction.stage === SaleTransactionStage.ForHandover && (
                        <>
                            <Grid className={styles.changeStageGridItem} lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(
                                            commonStyles.removeBoxShadow,
                                            commonStyles.defaultBorderRadius,
                                            commonStyles.primaryButtonBorder
                                        )}
                                        color="primary"
                                        onClick={onUpdateToPendingApproval}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.requestApproval')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <ErrorDialog
                                fields={validForApprovalFields}
                                onClose={() => setValidForApprovalFields(null)}
                            />
                        </>
                    )}

                    {[SaleTransactionStage.BiddingOpen, SaleTransactionStage.UpcomingBidding].includes(
                        saleTransaction.stage
                    ) && (
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <ActionCloseTransaction saleTransaction={saleTransaction} />
                            </FormControl>
                        </Grid>
                    )}
                </>
            )}

            {
                // eslint-disable-next-line max-len
                currentUser.type === UserType.Approver &&
                    saleTransaction.stage === SaleTransactionStage.PendingApproval && (
                        <>
                            <Grid className={styles.changeStageGridItem} lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(
                                            commonStyles.removeBoxShadow,
                                            commonStyles.defaultBorderRadius,
                                            commonStyles.primaryButtonBorder
                                        )}
                                        color="primary"
                                        onClick={onUpdateToCompleted}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.approve')}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(
                                            commonStyles.defaultBorderRadius,
                                            commonStyles.primaryButtonBorder
                                        )}
                                        color="primary"
                                        onClick={onUpdateToHandover}
                                        size="large"
                                        variant="outlined"
                                    >
                                        {t('saleTransactionsPage:button.reject')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </>
                    )
            }
        </Grid>
    );
};

export default BiddingTab;
