import { Box, Button, FormControl, ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../../../Session';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemWrapper: {
            display: 'flex',
            color: theme.palette.primary.contrastText,
            marginBottom: '16px',
            paddingLeft: '40px',
            height: '56px',
            '&:hover': {
                background: theme.palette.secondary.main,
            },
        },
        item: {
            display: 'flex',
            maxWidth: '256px',
        },
        tooltip: {
            fontSize: '0.875rem',
        },
        iconContainer: {
            minWidth: '24px',
            height: '24px',
            margin: 'auto',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        icon: {
            width: '24px',
            height: '24px',
            [`&.MuiSvgIcon-root`]: {
                fill: '#ffffff',
            },
        },
        logoutButton: {
            fontSize: '1rem',
            height: theme.spacing(8),
            color: '#ffffff',
            borderRadius: '0',
            boxShadow: 'none',
        },
    })
);

export type DrawerFooterProps = {
    open: boolean;
};

const DrawerFooter = ({ open }: DrawerFooterProps) => {
    const styles = useStyles();
    const { t } = useTranslation(['common']);

    const { setToken } = useSession();

    return (
        <Box style={{ borderTop: '1px solid #444445' }}>
            {!open && (
                <ListItem key="logout" className={styles.itemWrapper} onClick={() => setToken(null)} button>
                    <Box className={styles.item}>
                        <Tooltip
                            classes={{ tooltip: styles.tooltip }}
                            disableHoverListener={open}
                            placement="right"
                            title={t('common:logOut')}
                        >
                            <ListItemIcon className={styles.iconContainer}>
                                <ExitToApp className={styles.icon} />
                            </ListItemIcon>
                        </Tooltip>
                    </Box>
                </ListItem>
            )}
            {open && (
                <Box>
                    <FormControl fullWidth>
                        <Button
                            className={styles.logoutButton}
                            color="secondary"
                            onClick={() => setToken(null)}
                            variant="contained"
                        >
                            {t('common:logOut')}
                        </Button>
                    </FormControl>
                </Box>
            )}
        </Box>
    );
};

export default DrawerFooter;
