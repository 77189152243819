import { AppBar, Toolbar, Box, Typography, useMediaQuery, Button, Link, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';

const useHeaderStyles = makeStyles(theme => ({
    header: {
        marginBottom: 0,
        paddingBottom: 0,
    },
}));

const CreateBiddingFormHeader = ({ goToView }: { goToView: () => void }) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const headerStyles = useHeaderStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { isSubmitting } = useFormikContext();

    const submitLabel = t('saleTransactionsPage:button.publish');

    const onBack = useCallback(() => {
        goToView();
    }, [goToView]);

    if (isSmall) {
        return (
            <AppBar
                className={cs(basicStyles.appBar, basicStyles.lightBoxShadow, basicStyles.appBarShift)}
                elevation={0}
                position="fixed"
            >
                <Toolbar className={basicStyles.toolBar}>
                    <Box zIndex={1}>
                        <Link className={commonStyles.primary2} component="a" onClick={onBack} underline="none">
                            {t('common:portalBackButton')}
                        </Link>
                    </Box>
                    <Box display="flex" justifyContent="center" position="absolute" width="100%">
                        <Typography className={commonStyles.bigFontSize}>
                            {t('saleTransactionsPage:createAuctionTitleMobile')}
                        </Typography>
                    </Box>
                    <Box ml="auto">
                        <Button
                            className={commonStyles.primary2}
                            style={{ padding: 0, minWidth: 'auto' }}
                            type="submit"
                            variant="text"
                        >
                            {submitLabel}
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <>
            <Paper
                className={cs(basicStyles.backPaper, basicStyles.lightBoxShadow, basicStyles.stickyHeader)}
                elevation={0}
            >
                <Link className={cs(basicStyles.linkBack)} component="a" onClick={onBack}>
                    <ArrowBackIosIcon fontSize="small" /> {t('common:portalBackButton')}
                </Link>

                <Box className={basicStyles.rightToolbar} ml="auto">
                    <Box className={cs([commonStyles.buttonGroup])}>
                        <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                            {submitLabel}
                        </Button>
                    </Box>
                </Box>
            </Paper>

            <Box
                alignItems="center"
                className={cs([basicStyles.main, commonStyles.header, headerStyles.header])}
                display="flex"
            >
                <Typography className={commonStyles.title}>{t('saleTransactionsPage:createAuctionTitle')}</Typography>
            </Box>
        </>
    );
};

export default CreateBiddingFormHeader;
