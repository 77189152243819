import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import InfoIcon from '@material-ui/icons/Info';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../Session';
import { SaleTransactionStage, UserType } from '../../api';
import useCommonStyles from '../../useCommonStyles';
import { SaleTransactionTabValue } from './ViewSaleTransaction/ViewSaleTransactionBody';
import CarIcon from '../../icons/carIcon.svg';

export type MobileMenuTabsProps = {
    a11yProps: (index: SaleTransactionTabValue) => object;
    value: SaleTransactionTabValue;
    setValue: (value: SaleTransactionTabValue) => void;
    stage: SaleTransactionStage;
};

type MenuNavProps = {
    userType: UserType;
    commonStyles: ClassNameMap;
    t: TFunction;
    a11yProps: (index: SaleTransactionTabValue) => object;
    setValue: (value: SaleTransactionTabValue) => void;
    value: SaleTransactionTabValue;
};

const DraftSaleMobileMenuNav = ({ commonStyles, t, a11yProps, setValue, value }: Omit<MenuNavProps, 'userType'>) => (
    <BottomNavigation className={commonStyles.bottomNavigation} onChange={(_, value) => setValue(value)} value={value}>
        <BottomNavigationAction
            className={commonStyles.bottomNavigationAction}
            classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
            icon={<CarIcon className="MuiSvgIcon-root" />}
            label={t('saleTransactionsPage:menu.vehicle')}
            value={SaleTransactionTabValue.Vehicle}
            showLabel
            {...a11yProps(SaleTransactionTabValue.Vehicle)}
        />
    </BottomNavigation>
);

const NonDraftMobileMenuNav = ({ userType, commonStyles, t, a11yProps, setValue, value }: MenuNavProps) => (
    <BottomNavigation className={commonStyles.bottomNavigation} onChange={(_, value) => setValue(value)} value={value}>
        {userType !== UserType.Dealer && (
            <BottomNavigationAction
                className={commonStyles.bottomNavigationAction}
                classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                icon={<InfoIcon />}
                label={t('saleTransactionsPage:menu.transaction')}
                value={SaleTransactionTabValue.Transaction}
                showLabel
                {...a11yProps(SaleTransactionTabValue.Transaction)}
            />
        )}
        <BottomNavigationAction
            className={commonStyles.bottomNavigationAction}
            classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
            icon={<CarIcon className="MuiSvgIcon-root" />}
            label={t('saleTransactionsPage:menu.vehicle')}
            value={SaleTransactionTabValue.Vehicle}
            showLabel
            {...a11yProps(SaleTransactionTabValue.Vehicle)}
        />
        <BottomNavigationAction
            className={commonStyles.bottomNavigationAction}
            classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
            icon={<InfoIcon />}
            label={t('saleTransactionsPage:menu.bidding')}
            value={SaleTransactionTabValue.Bidding}
            showLabel
            {...a11yProps(SaleTransactionTabValue.Bidding)}
        />
    </BottomNavigation>
);

const MobileMenuNav = ({
    stage,
    userType,
    commonStyles,
    t,
    a11yProps,
    setValue,
    value,
}: MenuNavProps & { stage: SaleTransactionStage }) => {
    switch (stage) {
        case SaleTransactionStage.Drafted:
            return (
                <DraftSaleMobileMenuNav
                    a11yProps={a11yProps}
                    commonStyles={commonStyles}
                    setValue={setValue}
                    t={t}
                    value={value}
                />
            );

        default:
            return (
                <NonDraftMobileMenuNav
                    a11yProps={a11yProps}
                    commonStyles={commonStyles}
                    setValue={setValue}
                    t={t}
                    userType={userType}
                    value={value}
                />
            );
    }
};

const MobileMenuTabs = ({ value, setValue, a11yProps, stage }: MobileMenuTabsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const commonStyles = useCommonStyles();

    const currentUser = useCurrentUser();

    return (
        <MobileMenuNav
            a11yProps={a11yProps}
            commonStyles={commonStyles}
            setValue={setValue}
            stage={stage}
            t={t}
            userType={currentUser.type}
            value={value}
        />
    );
};

export default MobileMenuTabs;
