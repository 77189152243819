import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '../api';

const useUserTypeOptions = () => {
    const { t } = useTranslation(['common']);

    return useMemo(
        () => [
            { value: UserType.Dealer, label: t('common:userTypes.dealer') },
            { value: UserType.Admin, label: t('common:userTypes.admin') },
            // { value: UserType.SaleConsultant, label: t('common:userTypes.salesConsultant') },
            // { value: UserType.ValuationTeam, label: t('common:userTypes.valuationTeam') },
            // { value: UserType.Approver, label: t('common:userTypes.approver') },
        ],
        [t]
    );
};

export default useUserTypeOptions;
