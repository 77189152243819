import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { TFunction } from 'i18next';
import { isNil } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, SaleTransactionStage, SalesTransactionVehicle } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import FileList from '../../../../components/attachments/FileList';
import VehicleDiagram from '../../components/VehicleDiagram';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            paddingInlineStart: '16px',
        },
        item: {
            listStyleType: 'square',
        },
    })
);

export type VehicleTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const getContentValue = (value: any, key: string, t: TFunction) => {
    switch (key) {
        case 'beExported':
        case 'parfEligibility':
        case 'ownerHandbookCollected':
            return value ? t('common:yes') : t('common:no');

        case 'intendedDeregistrationDate':
        case 'originalRegistrationDate':
        case 'firstRegistrationDate':
        case 'opcCashRebateEligibilityExpiryDate':
        case 'parfEligibilityDate':
        case 'coeExpiryDate':
        case 'targetHandoverDateTime':
            return value
                ? t('common:formats.shortDate', {
                      date: new Date(value),
                  })
                : t('common:blankValue');

        case 'manufacturingYear':
        case 'transferCount':
        case 'coePeriodYear':
        case 'setOfKeys':
            return value ? value.toString() : t('common:blankValue');

        case 'mileage':
            return value ? `${value.toLocaleString()} ${t('common:shortKilometer')}` : t('common:blankValue');

        case 'openMarketValue':
        case 'actualARFPaid':
        case 'parfRebateAmount':
        case 'opcCashRebateAmount':
        case 'qpPaid':
        case 'pqpPaid':
        case 'coeRebateAmount':
        case 'totalRebateAmount':
            return value ? t('common:currency') + value.toLocaleString() : t('common:blankValue');

        default:
            return value;
    }
};

const getVehicleBasicInformation = (t: TFunction, saleTransaction: FullSaleTransactionDataFragment) => {
    const { vehicle } = saleTransaction;

    const vehicleDetailsValue = [
        {
            key: 'ownerId',
            label: 'nric',
            show: !isNil(vehicle.ownerId),
        },
        {
            key: 'number',
            label: 'vehicleNo',
            show: !isNil(vehicle.number),
        },
        {
            key: 'mileage',
            label: 'mileage',
            show: !isNil(vehicle.mileage),
        },
        {
            key: 'setOfKeys',
            label: 'setOfKeys',
            show: !isNil(vehicle.setOfKeys),
        },
        {
            key: 'targetHandoverDateTime',
            label: 'targetHandoverDate',
            show: !isNil(saleTransaction.handover?.targetHandoverDateTime),
        },
        {
            key: 'vehicleEquipment',
            label: 'vehicleEquipment',
            show: !isNil(vehicle.vehicleEquipment),
        },
    ];

    const filteredVehicleDetailsValue = vehicleDetailsValue.filter(details => details.show);

    return filteredVehicleDetailsValue.map(details => ({
        label: t(`saleTransactionsPage:basicInformation.${details.label}`),
        content: getContentValue(
            details.key === 'targetHandoverDateTime'
                ? saleTransaction.handover.targetHandoverDateTime
                : vehicle[details.key],
            details.key,
            t
        ),
    }));
};

const getVehicleDetailsValues = (t: TFunction, vehicle: SalesTransactionVehicle, stage: SaleTransactionStage) => {
    const vehicleDetailsValue = [
        {
            key: 'number',
            label: 'vehicleNo',
            show: stage !== SaleTransactionStage.Drafted,
        },
        {
            key: 'beExported',
            label: 'vehicleToBeExported',
            show: true,
        },
        {
            key: 'intendedDeregistrationDate',
            label: 'intendedDeregistrationDate',
            show: true,
        },
        {
            key: 'make',
            label: 'vehicleMake',
            show: true,
        },
        {
            key: 'model',
            label: 'vehicleModel',
            show: true,
        },
        {
            key: 'primaryColour',
            label: 'primaryColour',
            show: true,
        },
        {
            key: 'secondaryColour',
            label: 'secondaryColour',
            show: true,
        },
        {
            key: 'manufacturingYear',
            label: 'manufacturingYear',
            show: true,
        },
        {
            key: 'engineNumber',
            label: 'engineNo',
            show: true,
        },
        {
            key: 'chassisNumber',
            label: 'chassisNo',
            show: true,
        },
        {
            key: 'maximumPowerOutput',
            label: 'maxPowerOutput',
            show: true,
        },
        {
            key: 'openMarketValue',
            label: 'openMarketValue',
            show: true,
        },
        {
            key: 'originalRegistrationDate',
            label: 'originalRegistrationDate',
            show: true,
        },
        {
            key: 'firstRegistrationDate',
            label: 'firstRegistrationDate',
            show: true,
        },
        {
            key: 'transferCount',
            label: 'transferCount',
            show: true,
        },
        {
            key: 'actualARFPaid',
            label: 'actualArfPaid',
            show: true,
        },
        {
            key: 'opcCashRebateEligibility',
            label: 'opcCashRebateEligibility',
            show: true,
        },
        {
            key: 'opcCashRebateEligibilityExpiryDate',
            label: 'opcCashRebateEligibilityExpiryDate',
            show: true,
        },
        {
            key: 'opcCashRebateAmount',
            label: 'opcCashRebateAmount',
            show: true,
        },
        {
            key: 'parfEligibility',
            label: 'parfEligibility',
            show: true,
        },
        {
            key: 'parfEligibilityDate',
            label: 'parfEligibilityExpiryDate',
            show: true,
        },
        {
            key: 'parfRebateAmount',
            label: 'parfRebateValue',
            show: true,
        },
        {
            key: 'coeExpiryDate',
            label: 'coeExpiryDate',
            show: true,
        },
        {
            key: 'coeCategory',
            label: 'coeCategory',
            show: true,
        },
        {
            key: 'coePeriodYear',
            label: 'coePeriod',
            show: true,
        },
        {
            key: 'qpPaid',
            label: 'qpPaid',
            show: true,
        },
        {
            key: 'pqpPaid',
            label: 'pqpPaid',
            show: true,
        },
        {
            key: 'coeRebateAmount',
            label: 'coeRebateAmount',
            show: true,
        },
        {
            key: 'totalRebateAmount',
            label: 'totalRebateAmount',
            show: true,
        },
        {
            key: 'ltaMessage',
            label: 'ltaMessage',
            show: stage !== SaleTransactionStage.Drafted,
        },
        {
            key: 'mileage',
            label: 'mileage',
            show: stage !== SaleTransactionStage.Drafted,
        },
        {
            key: 'setOfKeys',
            label: 'setOfKeys',
            show: stage !== SaleTransactionStage.Drafted,
        },
        {
            key: 'ownerHandbookCollected',
            label: 'ownerHandbookCollected',
            show: true,
        },
    ];

    const filteredVehicleDetailsValue = vehicleDetailsValue.filter(
        details => details.show && !isNil(vehicle[details.key])
    );

    return filteredVehicleDetailsValue.map(details => ({
        label: t(`saleTransactionsPage:vehicle.${details.label}`),
        content: getContentValue(vehicle[details.key], details.key, t),
    }));
};

const VehicleTab = ({ saleTransaction }: VehicleTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const theme = useTheme();
    const styles = useStyles();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const useCardDesignForAccordion = !isSmall;
    const { stage } = saleTransaction;

    const vehicleBasicInformation = useMemo(() => getVehicleBasicInformation(t, saleTransaction), [saleTransaction, t]);

    const vehicleDetails = useMemo(
        () => getVehicleDetailsValues(t, saleTransaction.vehicle, stage),
        [saleTransaction.vehicle, stage, t]
    );

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                {vehicleBasicInformation.length > 0 && (
                    <Accordion
                        title={t('saleTransactionsPage:accordionTitle.basicInformation')}
                        useCardDesign={useCardDesignForAccordion}
                        defaultExpanded
                    >
                        <Grid spacing={1} container>
                            {vehicleBasicInformation.map(details => (
                                <Grid xs={12} item>
                                    <ContentWithLabel content={details.content} label={details.label} />
                                </Grid>
                            ))}
                        </Grid>
                    </Accordion>
                )}
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.vehicleDetails')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        {vehicleDetails.map(details => (
                            <Grid xs={12} item>
                                <ContentWithLabel content={details.content} label={details.label} />
                            </Grid>
                        ))}
                    </Grid>
                </Accordion>

                {saleTransaction.customerAssessment && (
                    <Accordion
                        title={t('saleTransactionsPage:accordionTitle.customerAssessment')}
                        useCardDesign={useCardDesignForAccordion}
                        defaultExpanded
                    >
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <Grid className={styles.list} component="ul" spacing={1} container>
                                    {saleTransaction.customerAssessment?.split('\n').map(field => (
                                        <Grid xs={12} item>
                                            <Box className={styles.item} component="li">
                                                {field}
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Accordion>
                )}

                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.uploadPhotos')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.photos.length > 0 ? (
                                        <FileList files={saleTransaction.photos} />
                                    ) : (
                                        <Typography>{t('saleTransactionsPage:noPhotos')}</Typography>
                                    )
                                }
                                display="block"
                                label={t('saleTransactionsPage:photos')}
                                mt={2}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.vehicleCondition')}
                    defaultExpanded
                    useCardDesign
                >
                    <VehicleDiagram comments={saleTransaction.vehicleDiagramComments} isEditable={false} />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
