import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadFileType } from '../../../api';

const useSaleTransactionFilename = () => {
    const { t } = useTranslation('common');

    return useCallback(
        ({ type = DownloadFileType.Excel, vehicleNumber = 'all', isListing = false }) => {
            const dateFormat = t('common:formats.fileNameDateFormat');
            const date = dayjs().format(dateFormat);
            const extension = type === DownloadFileType.Excel ? 'xlsx' : 'pdf';
            const filenamePrefix = isListing ? 'tradein_all' : `tradein_${vehicleNumber}`;

            return `${filenamePrefix}_${date}.${extension}`;
        },
        [t]
    );
};

export default useSaleTransactionFilename;
