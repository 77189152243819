import { validators } from '@amille/simple-validators';
import { Box } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    imageFileExtensions,
    maxUploadFileSize,
    maxValuationPhotos,
    pdfFileExtension,
} from '../../../../server/schema/constants';
import {
    SalesTransactionAction,
    useAddSalesTransactionVehicleDiagramCommentMutation,
    useCreateSaleTransactionMutation,
    useUploadSalesTransactionPhotoMutation,
} from '../../../api';
import Accordion from '../../../components/Accordion';
import FileViewerProvider from '../../../components/FileViewerProvider';
import { AttachmentButton, FrontPhotoManager } from '../../../components/attachments';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import { VehicleForm } from '../Forms';
import BasicInformationForm from '../Forms/BasicInformationForm';
import CustomerAssessmentForm from '../Forms/CustomerAssessmentForm';
import TradeInDetailsForm from '../Forms/TradeInDetailsForm';
import { CheckDuplicateCarPlate } from '../components/DuplicateSalesTransactionProvider';
import VehicleDiagram, { VehicleDiagramComment } from '../components/VehicleDiagram';
import { useStyles } from './CreateSaleTransactionBody';
import CreateSaleTransactionHeader from './CreateSaleTransactionHeader';
import { Page, useCreateSalesTransactionState } from './CreateSalesTransactionStateProvider';
import { CreateSaleTransactionFormValues } from '.';

const formValidator = validators.compose(
    validators.requiredDate('handover.targetHandoverDateTime'),
    validators.requiredString('vehicle.number'),
    validators.requiredNumber('vehicle.mileage'),
    validators.requiredDate('vehicle.intendedDeregistrationDate'),
    validators.requiredString('vehicle.make'),
    validators.requiredString('vehicle.model'),
    validators.requiredString('vehicle.primaryColour'),
    validators.requiredNumber('vehicle.manufacturingYear'),
    validators.requiredString('vehicle.engineNumber'),
    validators.requiredString('vehicle.chassisNumber'),
    validators.requiredString('vehicle.maximumPowerOutput'),
    validators.requiredNumber('vehicle.openMarketValue'),
    validators.requiredDate('vehicle.originalRegistrationDate'),
    validators.requiredDate('vehicle.firstRegistrationDate'),
    validators.requiredNumber('vehicle.transferCount'),
    validators.requiredNumber('vehicle.actualARFPaid'),
    validators.requiredDate('vehicle.coeExpiryDate'),
    validators.requiredString('vehicle.coeCategory'),
    validators.requiredNumber('vehicle.coePeriodYear'),
    validators.requiredNumber('vehicle.setOfKeys'),
    validators.only(
        ({ vehicle: { parfEligibility } }) => parfEligibility === true,
        validators.compose(validators.requiredDate('vehicle.parfEligibilityDate'))
    ),
    validators.requiredString('action')
);

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;

const allowedExtensions = [pdfFileExtension, ...imageFileExtensions];

export type CreateSalesTransactionFormProps = {
    checkDuplicateCarPlate: CheckDuplicateCarPlate;
};

const CreateSalesTransactionForm = ({ checkDuplicateCarPlate }: CreateSalesTransactionFormProps) => {
    const history = useHistory();
    const { t } = useTranslation('saleTransactionsPage');
    const [createSaleTransaction] = useCreateSaleTransactionMutation();
    const [uploadSalesTransactionPhotoMutation] = useUploadSalesTransactionPhotoMutation();
    const [addVehicleDiagramComment] = useAddSalesTransactionVehicleDiagramCommentMutation();

    const styles = useStyles();
    const basicStyles = useBasicStyle();

    const {
        state: { formValue, comments },
        actions,
    } = useCreateSalesTransactionState();

    const onSubmit = useHandleError(
        async (values: CreateSaleTransactionFormValues) => {
            const isDuplicated = await checkDuplicateCarPlate(values.vehicle);

            if (!isDuplicated) {
                if (values.action === SalesTransactionAction.Offers) {
                    actions.setFormValue(values);
                    actions.setCurrentPage(Page.OfferSession);

                    return;
                }

                const { photos, frontPagePhoto, ...fieldValue } = values;

                const {
                    data: {
                        createSaleTransaction: { id },
                    },
                } = await createSaleTransaction({
                    variables: { fields: fieldValue },
                });

                await Promise.all([
                    ...photos.map(async file => {
                        await uploadSalesTransactionPhotoMutation({
                            variables: {
                                salesTransactionId: id,
                                file,
                                isFrontPagePhoto: frontPagePhoto === file,
                            },
                        });
                    }),
                ]);

                // need to save comment sequently
                for (const comment of comments) {
                    // eslint-disable-next-line no-await-in-loop
                    await addVehicleDiagramComment({
                        variables: {
                            fields: comment,
                            salesTransactionId: id,
                        },
                    });
                }

                history.goBack();
            }
        },
        [history, actions, comments, createSaleTransaction, uploadSalesTransactionPhotoMutation]
    );

    const addComment = useCallback(
        (newComment: VehicleDiagramComment) => {
            actions.setComments([...comments, newComment]);
        },
        [actions, comments]
    );

    const removeComment = useCallback(
        (removeIndex: number) => {
            actions.setComments(comments.filter((_, index: number) => removeIndex !== index));
        },
        [actions, comments]
    );

    const validate = useValidator(formValidator);

    return (
        <Box className={styles.root}>
            <Formik initialValues={formValue} onSubmit={onSubmit} validate={validate}>
                {() => (
                    <Form>
                        <FileViewerProvider>
                            <CreateSaleTransactionHeader currentPage={Page.VehicleForm} />
                            <Box className={basicStyles.mainDesktop}>
                                <Accordion
                                    title={t('saleTransactionsPage:accordionTitle.basicInformation')}
                                    defaultExpanded
                                    useCardDesign
                                >
                                    <BasicInformationForm />
                                </Accordion>

                                <Accordion
                                    title={t('saleTransactionsPage:accordionTitle.tradeInDetails')}
                                    defaultExpanded
                                    useCardDesign
                                >
                                    <TradeInDetailsForm />
                                </Accordion>
                                <Accordion
                                    title={t('saleTransactionsPage:vehicleDetails')}
                                    defaultExpanded
                                    useCardDesign
                                >
                                    <VehicleForm />
                                </Accordion>

                                <Accordion
                                    title={t('saleTransactionsPage:accordionTitle.customerAssessment')}
                                    defaultExpanded
                                    useCardDesign
                                >
                                    <CustomerAssessmentForm />
                                </Accordion>

                                <Accordion
                                    title={t('saleTransactionsPage:accordionTitle.uploadPhotos')}
                                    defaultExpanded
                                    useCardDesign
                                >
                                    <FrontPhotoManager attachmentFieldName="photos" name="frontPagePhoto">
                                        {renderPrefix => (
                                            <AttachmentButton
                                                allowedExtensions={allowedExtensions}
                                                buttonText={t('common:upload')}
                                                icon={<PublishIcon fontSize="small" />}
                                                label={t('saleTransactionsPage:transactionDetails.photos')}
                                                max={maxValuationPhotos}
                                                maxSize={maxFileSizeInKB}
                                                name="photos"
                                                renderPrefix={renderPrefix}
                                            />
                                        )}
                                    </FrontPhotoManager>
                                </Accordion>
                                <Accordion
                                    title={t('saleTransactionsPage:accordionTitle.vehicleCondition')}
                                    defaultExpanded
                                    useCardDesign
                                >
                                    <VehicleDiagram
                                        addComment={addComment}
                                        comments={comments}
                                        removeComment={removeComment}
                                        isEditable
                                    />
                                </Accordion>
                            </Box>
                        </FileViewerProvider>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateSalesTransactionForm;
