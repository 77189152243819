import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { FullSaleTransactionDataFragment } from '../../../api';
import mergeWithDefault from '../../../utilities/mergeWithDefault';
import useHandoverLocationOptions, { defaultOtherLocationOption } from '../../../utilities/useHandoverLocationOptions';
import { EditSaleTransactionFormValues } from './EditSaleTransactionForm';

export enum Page {
    VehiclePrefill = 'vehiclePrefill',
    VehicleForm = 'vehicleForm',
}

const defaultValue: EditSaleTransactionFormValues = {
    vehicle: {
        number: '',
        beExported: true,
        intendedDeregistrationDate: null,
        make: '',
        model: '',
        primaryColour: '',
        manufacturingYear: null,
        engineNumber: '',
        chassisNumber: '',
        maximumPowerOutput: '',
        openMarketValue: null,
        originalRegistrationDate: null,
        firstRegistrationDate: null,
        transferCount: null,
        actualARFPaid: null,
        parfEligibility: true,
        parfEligibilityDate: null,
        coeExpiryDate: null,
        coeCategory: '',
        coePeriodYear: null,
        mileage: null,
        importMethod: '',
        secondaryColour: '',
        opcCashRebateAmount: null,
        opcCashRebateEligibilityExpiryDate: null,
        opcCashRebateEligibility: '',
        pqpPaid: null,
        totalRebateAmount: null,
        ltaMessage: '',
        coeRebateAmount: null,
        parfRebateAmount: null,
        qpPaid: null,
        setOfKeys: null,
        ownerHandbookCollected: false,
    },
    customerAssessment: '',
    photos: [],
    handover: {
        targetHandoverDateTime: null,
        handoverLocationField: null,
        attachments: [],
        vsaAttachments: [],
    },
    action: null,
};

export type State = {
    prefillRaw: string;
    initialValues: InitialEditSaleTransactionFormValues | null;
    formValues: InitialEditSaleTransactionFormValues | null;
    currentPage: Page;
};

export type SetPrefillRaw = { type: 'setPrefillRaw'; prefillRaw: string };
export type SetFormValues = { type: 'setFormValues'; formValues: InitialEditSaleTransactionFormValues };
export type SetInitialValues = { type: 'setInitialValues'; initialValues: InitialEditSaleTransactionFormValues };
export type SetCurrentPage = { type: 'setCurrentPage'; currentPage: Page };

export type Action = SetPrefillRaw | SetInitialValues | SetFormValues | SetCurrentPage;

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'setPrefillRaw':
            return { ...state, prefillRaw: action.prefillRaw };

        case 'setInitialValues':
            return { ...state, initialValues: action.initialValues };

        case 'setFormValues':
            return { ...state, formValues: action.formValues };

        case 'setCurrentPage':
            return { ...state, currentPage: action.currentPage };

        default:
            return state;
    }
};

const initialState: State = {
    prefillRaw: '',
    initialValues: null,
    formValues: null,
    currentPage: Page.VehicleForm,
};

export const useEditSaleTransactionReducer = () => useReducer(reducer, initialState);

export type ActionMethods = {
    setPrefillRaw: (prefillRaw: string) => void;
    setInitialValues: (initialValues: InitialEditSaleTransactionFormValues) => void;
    setFormValues: (formValues: InitialEditSaleTransactionFormValues) => void;
    setCurrentPage: (currentPage: Page) => void;
};

export type EditSaleTransactionContext = {
    state: State;
    actions: ActionMethods;
};

const Context = createContext<EditSaleTransactionContext | null>(null);

export const useEditSaleTransactionState = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useEditSaleTransactionState must be used within a EditSaleTransactionStateProvider');
    }

    return context;
};

type InitialEditSaleTransactionFormValues = Omit<EditSaleTransactionFormValues, 'vehicle'> & {
    vehicle?: FullSaleTransactionDataFragment['vehicle'];
};

const computeInitialValues = (
    values: FullSaleTransactionDataFragment,
    handoverLocationOptions: Array<{ value: string; label: string }>
): InitialEditSaleTransactionFormValues => {
    const {
        id,
        stage,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        purchaseTransaction,
        latestBiddingSession,
        latestBiddingSessionEntry,
        awardedBiddingEntry,
        handover,
        activities,
        lastActivity,
        identifier,
        frontPagePhotoSourceId,
        vehicleDiagramComments,
        vehicle,
        ...fields
    } = values;

    const { imported, ...vehicleFields } = vehicle;

    const {
        targetHandoverDateTime,
        handoverLocation,
        personResponsible,
        vsaAttachment,
        attachments,
        ...handoverFields
    } = handover ?? {};
    const handoverLocationOption = handoverLocationOptions.find(option => option.value === handoverLocation);

    const handoverLocationField = {
        main: handoverLocationOption ? handoverLocationOption.value : defaultOtherLocationOption,
        other: !handoverLocationOption ? handoverLocation : '',
    };

    return {
        ...fields,
        vehicle: mergeWithDefault(vehicleFields, defaultValue.vehicle),
        handover: handover && {
            targetHandoverDateTime: targetHandoverDateTime as Date,
            handoverLocationField: handoverLocation ? handoverLocationField : { main: '' },
            personResponsible: personResponsible?.id || '',
            vsaAttachments: vsaAttachment ? [vsaAttachment] : [],
            attachments: attachments ?? [],
            ...handoverFields,
        },
        biddingSession: latestBiddingSession && {
            name: latestBiddingSession.sessionName,
            notes: latestBiddingSession.notes,
        },
        // switch it with the original document
        frontPagePhoto: fields.photos.find(document => document.id === frontPagePhotoSourceId),
        // comments
        comments: vehicleDiagramComments.map(comment => ({
            id: comment.id,
            index: comment.index,
            comment: comment.comment,
            position: comment.position,
        })),
    };
};

export type EditSaleTransactionStateProviderProps = {
    children: JSX.Element | React.ReactNode;
    saleTransaction: FullSaleTransactionDataFragment;
};

const EditSaleTransactionStateProvider = ({ children, saleTransaction }: EditSaleTransactionStateProviderProps) => {
    const [state, dispatch] = useEditSaleTransactionReducer();

    const actions = useMemo(
        (): ActionMethods => ({
            setPrefillRaw: prefillRaw => dispatch({ type: 'setPrefillRaw', prefillRaw }),
            setInitialValues: initialValues => dispatch({ type: 'setInitialValues', initialValues }),
            setFormValues: formValues => dispatch({ type: 'setFormValues', formValues }),
            setCurrentPage: currentPage => dispatch({ type: 'setCurrentPage', currentPage }),
        }),
        [dispatch]
    );

    const handoverLocationOptions = useHandoverLocationOptions();
    const initialValues = useMemo(
        () => computeInitialValues(saleTransaction, handoverLocationOptions),
        [handoverLocationOptions, saleTransaction]
    );

    useEffect(() => {
        if (!state.initialValues) {
            dispatch({ type: 'setInitialValues', initialValues });
        }
    }, [dispatch, initialValues, state.initialValues]);

    const context = useMemo((): EditSaleTransactionContext => ({ state, actions }), [state, actions]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default EditSaleTransactionStateProvider;
