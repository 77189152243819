import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    CreateBiddingMutationVariables,
    FullSaleTransactionDataFragment,
    useCreateBiddingMutation,
} from '../../../api';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import { CreateBiddingSessionBody } from '../../BiddingSessions/components/CreateBiddingSession';
import getOfferSessionName from '../CreateSaleTransaction/utilities/getOfferSessionName';
import CreateBiddingFormHeader from './CreateBiddingFormHeader';

export type FormValues = Pick<
    CreateBiddingMutationVariables['fields'],
    'sessionName' | 'notes' | 'startPeriod' | 'endPeriod'
> & {
    saleTransactions: FullSaleTransactionDataFragment[];
};

type CreateBiddingFormProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    goToView: () => void;
};

const CreateBiddingForm = ({ saleTransaction, goToView }: CreateBiddingFormProps) => {
    const { t } = useTranslation(['biddingSessionsPage']);
    const history = useHistory();
    const [createBidding] = useCreateBiddingMutation();

    const basicStyles = useBasicStyle();

    const formValidator = useMemo(
        () =>
            validators.compose(
                validators.requiredString('sessionName'),
                validators.requiredDate('startPeriod', t('biddingSessionsPage:session.invalidDate')),
                validators.requiredDate('endPeriod', t('biddingSessionsPage:session.invalidDate')),
                validators.validDateBeforeReferenceField(
                    'startPeriod',
                    'endPeriod',
                    t('biddingSessionsPage:session.invalidDate')
                )
            ),
        [t]
    );

    const onSubmit = useHandleError(
        async ({ saleTransactions, ...others }: FormValues) => {
            // Creating bidding session
            await createBidding({
                variables: {
                    fields: {
                        ...others,
                        saleTransactionIds: [saleTransaction.id],
                    },
                },
            });

            history.goBack();
        },
        [history]
    );

    const initialValues: FormValues = useMemo(
        () => ({
            sessionName: getOfferSessionName(saleTransaction.vehicle),
            endPeriod: dayjs().add(1, 'd').toDate(),
            startPeriod: dayjs().toDate(),
            saleTransactions: [saleTransaction],
        }),
        [saleTransaction]
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <CreateBiddingFormHeader goToView={goToView} />
                    <Box className={basicStyles.mainDesktop}>
                        <CreateBiddingSessionBody />
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default CreateBiddingForm;
