import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';

const CustomerAssessmentForm = () => {
    const { t } = useTranslation('saleTransactionsPage');

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    name="customerAssessment"
                    placeholder={t('saleTransactionsPage:accordionTitle.customerAssessment')}
                    multiline
                />
            </Grid>
        </Grid>
    );
};

export default CustomerAssessmentForm;
