import { InputAdornment as MuiInputAdornment, InputAdornmentProps as MuiInputAdornmentProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';

const useStyles = makeStyles(() => ({
    adornment: {
        display: 'flex',
        columnGap: '16px',
        marginRight: '4px',
    },
    requiredIndicator: {
        color: '#EA001E',
        fontSize: '12px',
    },
}));

type Props = { required?: boolean };
type InputAdornmentProps = MuiInputAdornmentProps & Props;

const InputAdornment = ({ children, required, ...props }: InputAdornmentProps) => {
    const classes = useStyles();

    if (!required) {
        return <MuiInputAdornment {...props}>{children}</MuiInputAdornment>;
    }

    return (
        <MuiInputAdornment {...props} className={classes.adornment}>
            {children}
            <span className={classes.requiredIndicator}>*</span>
        </MuiInputAdornment>
    );
};

export const PasswordAdornment = ({ required }: Props) => (
    <InputAdornment position="start" required={required}>
        <LockOutlinedIcon />
    </InputAdornment>
);

export const EmailAdornment = ({ required }: Props) => (
    <InputAdornment position="start" required={required}>
        <EmailOutlinedIcon />
    </InputAdornment>
);

export const PhoneAdornment = ({ required }: Props) => (
    <InputAdornment position="start" required={required}>
        <PhoneAndroidIcon />
    </InputAdornment>
);

export const PersonAdornment = ({ required }: Props) => (
    <InputAdornment position="start" required={required}>
        <PersonOutlineOutlinedIcon />
    </InputAdornment>
);

export const BusinessAdornment = ({ required }: Props) => (
    <InputAdornment position="start" required={required}>
        <StorefrontIcon />
    </InputAdornment>
);

export const PeopleAdornment = ({ required }: Props) => (
    <InputAdornment position="start" required={required}>
        <SupervisorAccountOutlinedIcon />
    </InputAdornment>
);
