import { EditSaleTransactionProps } from './EditSaleTransactionForm';
import EditSaleTransactionInner from './EditSaleTransactionInner';
import EditSaleTransactionStateProvider from './EditSalesTransactionStateProvider';

const EditSaleTransaction = ({ saleTransaction, goToView }: EditSaleTransactionProps) => (
    <EditSaleTransactionStateProvider saleTransaction={saleTransaction}>
        <EditSaleTransactionInner goToView={goToView} saleTransaction={saleTransaction} />
    </EditSaleTransactionStateProvider>
);

export default EditSaleTransaction;
