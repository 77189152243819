import { Grid, InputAdornment } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adornmentRoot: {
            '& > p': {
                color: theme.palette.secondary.main,
            },
        },
    })
);

const BasicInformationForm = () => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const styles = useStyles();
    const currentUser = useCurrentUser();

    const disabled = useMemo(
        () => ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type),
        [currentUser]
    );

    return (
        <Grid spacing={2} container>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={disabled}
                    label={t('saleTransactionsPage:basicInformation.nric')}
                    name="vehicle.ownerId"
                    placeholder={t('saleTransactionsPage:basicInformation.nric')}
                    required
                />
            </Grid>

            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={disabled}
                    label={t('saleTransactionsPage:basicInformation.vehicleNo')}
                    name="vehicle.number"
                    placeholder={t('saleTransactionsPage:basicInformation.vehicleNo')}
                    required
                />
            </Grid>

            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={![UserType.Admin, UserType.SaleConsultant, UserType.Approver].includes(currentUser.type)}
                    endAdornment={
                        <InputAdornment classes={{ root: styles.adornmentRoot }} position="end">
                            {t('common:shortKilometer')}
                        </InputAdornment>
                    }
                    label={t('saleTransactionsPage:basicInformation.mileage')}
                    name="vehicle.mileage"
                    placeholder={t('saleTransactionsPage:basicInformation.mileage')}
                    type="number"
                    required
                />
            </Grid>

            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:basicInformation.setOfKeys')}
                    name="vehicle.setOfKeys"
                    placeholder={t('saleTransactionsPage:basicInformation.setOfKeys')}
                    type="number"
                />
            </Grid>

            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.SaleConsultant
                    }
                    label={t('saleTransactionsPage:basicInformation.targetHandoverDate')}
                    name="handover.targetHandoverDateTime"
                    placeholder={t('saleTransactionsPage:basicInformation.targetHandoverDate')}
                    required
                />
            </Grid>

            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:basicInformation.vehicleEquipment')}
                    name="vehicle.vehicleEquipment"
                    placeholder={t('saleTransactionsPage:basicInformation.vehicleEquipment')}
                    multiline
                />
            </Grid>
        </Grid>
    );
};

export default BasicInformationForm;
