import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedPasswordField from '../../../../components/fields/OutlinedPasswordField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import {
    BusinessAdornment,
    PasswordAdornment,
    EmailAdornment,
    PhoneAdornment,
    PersonAdornment,
    PeopleAdornment,
} from '../../../../components/fields/adornments';
import usePortalStyles from '../../../../layouts/PortalLayout/usePortalStyles';
import useCommonStyles from '../../../../useCommonStyles';
import { defaultOtherBrandOption } from '../../../../utilities/useBrandOptions';
import useUserTypeOptions from '../../../../utilities/useUserTypeOptions';
import { SignUpFormValues } from './index';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerNote: {
            fontSize: '0.75rem',
            marginTop: '0.5rem',
        },
    })
);

const SignUpFormFields = () => {
    const { t } = useTranslation(['signUpPage']);
    const commonStyles = useCommonStyles();
    const portalStyles = usePortalStyles();
    const styles = useStyles();
    const { values, setFieldValue } = useFormikContext<SignUpFormValues>();
    const { type: userType, brand } = values;

    // const brandOptions = useBrandOptions();
    const userTypeOptions = useUserTypeOptions();

    useEffect(() => {
        if (userType !== UserType.SaleConsultant) {
            // reset brand
            setFieldValue('brand', { main: '' });
        } else {
            setFieldValue('brand', { main: t('common:brands.others') });
        }

        if (userType !== UserType.Dealer) {
            // remove business
            setFieldValue('business', undefined);
        }
    }, [userType, setFieldValue, t]);

    useEffect(() => {
        if (brand.main !== defaultOtherBrandOption) {
            // remove other brand
            setFieldValue('brand.other', undefined);
        } else {
            setFieldValue('brand.other', t('common:brands.porsche'));
        }
    }, [brand, setFieldValue, t]);

    return (
        <>
            <Grid xs={12} item>
                <OutlinedInputField
                    className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                    name="fullName"
                    placeholder={t('signUpPage:fields.fullNamePlaceholder')}
                    startAdornment={<PersonAdornment required />}
                    required
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                    name="email"
                    placeholder={t('signUpPage:fields.emailPlaceholder')}
                    startAdornment={<EmailAdornment required />}
                    required
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                    name="mobilePhone.number"
                    placeholder={t('signUpPage:fields.phonePlaceholder')}
                    startAdornment={<PhoneAdornment required />}
                />
                <Typography className={cs(styles.footerNote, commonStyles.secondary)}>
                    {t('signUpPage:phoneFooter')}
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                    name="type"
                    options={userTypeOptions}
                    placeholder={t('signUpPage:fields.userTypePlaceholder')}
                    startAdornment={<PeopleAdornment required />}
                />
            </Grid>
            {/* {userType === UserType.SaleConsultant && (
                <>
                    <Grid xs={12} item>
                        <OutlinedSelectField
                            className={cs(portalStyles.inputField, commonStyles.secondary)}
                            inputProps={{
                                classes: {
                                    icon: commonStyles.fillSecondary,
                                },
                            }}
                            name="brand.main"
                            options={brandOptions}
                            placeholder={t('signUpPage:fields.brandPlaceholder')}
                            startAdornment={businessAdornment}
                        />
                    </Grid>
                    {brand.main === defaultOtherBrandOption && (
                        <Grid xs={12} item>
                            <OutlinedInputField
                                className={cs(portalStyles.inputField, commonStyles.secondary)}
                                name="brand.other"
                                placeholder={t('signUpPage:fields.otherBrandPlaceholder')}
                                startAdornment={businessAdornment}
                            />
                        </Grid>
                    )}
                </>
            )} */}
            <Grid xs={12} item>
                <OutlinedPasswordField
                    className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                    name="password"
                    placeholder={t('signUpPage:fields.passwordPlaceholder')}
                    startAdornment={<PasswordAdornment required />}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedPasswordField
                    className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                    name="confirmPassword"
                    placeholder={t('signUpPage:fields.confirmPasswordPlaceholder')}
                    startAdornment={<PasswordAdornment required />}
                />
            </Grid>
            {userType === UserType.Dealer && (
                <>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                            name="business.name"
                            placeholder={t('signUpPage:fields.dealerBusinessNamePlaceholder')}
                            startAdornment={<BusinessAdornment required />}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                            name="business.registrationNumber"
                            placeholder={t('signUpPage:fields.dealerRocPlaceholder')}
                            startAdornment={<BusinessAdornment required />}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            className={cs(portalStyles.inputFieldLight, commonStyles.primary)}
                            name="business.address"
                            placeholder={t('signUpPage:fields.dealerBusinessAddressPlaceholder')}
                            startAdornment={<BusinessAdornment required />}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export default SignUpFormFields;
