import { Card, CardContent, Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
import cs from 'classnames';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import useLiveDuration from '../../../../utilities/useLiveDuration';

export type BiddingLiveDurationProps = {
    transaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            transform: 'translate(0, -50%)',
            width: '100%',
        },
        title: {
            textAlign: 'center',
            color: '#717073',
            fontSize: '13px',
        },
        content: {
            marginLeft: theme.spacing(1),
            fontSize: '15px',
        },
        counter: {
            color: '#F50537',
        },
    })
);

type biddingLiveDurationValues = {
    date: Date | string;
    title: string;
};

const BiddingLiveDuration = ({ transaction }: BiddingLiveDurationProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['saleTransactionsPage']);

    const { date, title } = useMemo((): biddingLiveDurationValues => {
        const now = new Date();
        const startPeriod = transaction.latestBiddingSession?.startPeriod;
        const start = !isEmpty(startPeriod) ? dayjs(startPeriod).toDate() : null;
        const endPeriod = transaction.latestBiddingSession?.endPeriod;
        const end = !isEmpty(endPeriod) ? dayjs(endPeriod).toDate() : null;

        if (start && now < start) {
            return {
                date: transaction.latestBiddingSession.startPeriod,
                title: t('saleTransactionsPage:dealerBiddings.biddingOpensIn'),
            };
        }

        if (end && now < end) {
            return {
                date: transaction.latestBiddingSession.endPeriod,
                title: t('saleTransactionsPage:dealerBiddings.remainBiddingDuration'),
            };
        }

        return { date: null, title: '' };
    }, [t, transaction]);

    const liveDuration = useLiveDuration(date);

    return (
        <Box className={styles.root}>
            <Card className={commonStyles.cardBoxShadow}>
                <CardContent>
                    <Box>
                        <Typography className={styles.title}>{title}</Typography>
                        <Box display="flex" justifyContent="center">
                            <ScheduleIcon className={styles.counter} color="secondary" />
                            <Typography className={cs(styles.content, styles.counter)} color="secondary">
                                {liveDuration}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default BiddingLiveDuration;
