type EssentialVehicle = {
    manufacturingYear?: number;
    make?: string;
    model?: string;
    number?: string;
};

const getOfferSessionName = (vehicle: EssentialVehicle) =>
    `${vehicle.manufacturingYear} ${vehicle.make} ${vehicle.model} ${vehicle.number}`;

export default getOfferSessionName;
