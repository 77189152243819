import { isNil } from 'lodash/fp';
import React, { useState } from 'react';
import { useCurrentUser } from '../../Session';
import { useGetSaleTransactionQuery, UserType } from '../../api';
import NotFoundPage from '../NotFoundPage';
import CreateBiddingForm from './CreateBidding/CreateBiddingForm';
import EditSaleTransaction from './EditSaleTransactions';
import ViewSaleTransaction from './ViewSaleTransaction';
import { DownloadSalesTransactionDetailFileProvider } from './components';

export type SaleTransactionDetailProps = {
    id: string;
};

enum SaleTransactionPage {
    View = 'View',
    Edit = 'Edit',
    Auction = 'Auction',
}

const SaleTransactionDetail = ({ id }: SaleTransactionDetailProps) => {
    const [page, setPage] = useState<SaleTransactionPage>(SaleTransactionPage.View);

    const currentUser = useCurrentUser();

    const { data, loading } = useGetSaleTransactionQuery({
        fetchPolicy: 'network-only',
        variables: { transactionId: id },
    });

    const saleTransaction = data?.saleTransaction;

    if (loading) {
        return null;
    }

    if (isNil(saleTransaction)) {
        return <NotFoundPage />;
    }

    switch (page) {
        case SaleTransactionPage.View:
            return (
                <DownloadSalesTransactionDetailFileProvider>
                    {downloadFile => (
                        <ViewSaleTransaction
                            downloadSalesTransactionDetailFile={downloadFile}
                            goToEdition={() => {
                                if (currentUser.type !== UserType.Dealer) {
                                    setPage(SaleTransactionPage.Edit);
                                }
                            }}
                            goToNewBidding={() => {
                                if (currentUser.type !== UserType.Dealer) {
                                    setPage(SaleTransactionPage.Auction);
                                }
                            }}
                            saleTransaction={saleTransaction}
                        />
                    )}
                </DownloadSalesTransactionDetailFileProvider>
            );

        case SaleTransactionPage.Edit:
            return (
                <EditSaleTransaction
                    goToView={() => setPage(SaleTransactionPage.View)}
                    saleTransaction={saleTransaction}
                />
            );

        case SaleTransactionPage.Auction:
            return (
                <CreateBiddingForm
                    goToView={() => setPage(SaleTransactionPage.View)}
                    saleTransaction={saleTransaction}
                />
            );

        default:
            return <NotFoundPage />;
    }
};

export default SaleTransactionDetail;
