import { Avatar, Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useCurrentUser } from '../../../../Session';
import usePublic from '../../../../utilities/usePublic';
import { contrastBackgroundColor } from '../../useBasicStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: contrastBackgroundColor,
            height: theme.spacing(45),
        },
        backgroundImage: {
            height: theme.spacing(45),
            backgroundSize: 'cover',
        },
        closeIcon: {
            color: '#fff',
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        settings: {
            display: 'flex',
            justifyContent: 'center',
        },
        settingItems: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
        },
        profilePicture: {
            width: '68px',
            height: '68px',
            alignSelf: 'center',
        },
        logoWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        logo: {
            height: '34px',
        },
        userInfo: {
            color: theme.palette.secondary.contrastText,
            fontSize: '16px',
            width: '250px',
            whiteSpace: 'normal',
            textAlign: 'center',
        },
    })
);

export type DrawerHeaderProps = {
    setOpen: (value: boolean) => void;
};

const DrawerHeader = ({ setOpen }: DrawerHeaderProps) => {
    const { t } = useTranslation(['homePage']);
    const styles = useStyles();
    const history = useHistory();
    const user = useCurrentUser();

    // const image = usePublic('assets/images/loginBackground.png');
    const drawerLogo = usePublic('assets/images/Audi_Rings_wh-RGB.png');

    return (
        <Box className={styles.root}>
            <div className={styles.backgroundImage} />
            <Box position="absolute" top={0} width="100%">
                <Grid container>
                    <Grid xs={12} item>
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton className={styles.closeIcon} onClick={() => setOpen(true)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box position="absolute" top={85} width="100%" zIndex={2}>
                <Grid container>
                    <Grid className={styles.settings} xs={12} item>
                        <Box className={styles.settingItems}>
                            <Box className={styles.logoWrapper}>
                                <img alt="logo" className={styles.logo} src={drawerLogo} />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Avatar className={styles.profilePicture} src={user.profilePicture?.signedUrl} />
                            </Box>
                            <Box className={styles.userInfo}>
                                <Typography>{t('homePage:welcomeBackUser', { name: user.fullName })}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Button
                                    color="secondary"
                                    onClick={() => history.push('/profile')}
                                    size="large"
                                    variant="contained"
                                >
                                    {t('homePage:button.profile')}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default DrawerHeader;
