import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { omit } from 'lodash/fp';
import { useMemo } from 'react';
import {
    UpdateUserMutationVariables,
    useUpdateUserMutation,
    NameOnlyFileDataFragment,
    useUploadProfilePictureMutation,
    UserType,
} from '../../../api';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import ProfileContainer from './ProfileContainer';
import ProfileUpdateBody, { ProfileUpdateBodyProps } from './ProfileUpdateBody';
import ProfileUpdateFooter from './ProfileUpdateFooter';
import ProfileUpdateHeader, { ProfileHeaderProps } from './ProfileUpdateHeader';

export type ProfileUpdateProps = ProfileHeaderProps & ProfileUpdateBodyProps;

export type ProfileUpdateFormValues = UpdateUserMutationVariables['fields'] & {
    id: string;
    profilePicture: NameOnlyFileDataFragment | File;
};

const ProfileUpdate = ({ goToView, user }: ProfileUpdateProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const [updateUser] = useUpdateUserMutation();
    const [uploadProfilePicture] = useUploadProfilePictureMutation();

    const onSubmit = useHandleError(
        async ({ id, profilePicture, ...fields }: ProfileUpdateFormValues) => {
            if (profilePicture && profilePicture instanceof File) {
                await uploadProfilePicture({
                    variables: { userId: id, file: profilePicture },
                });
            }

            await updateUser({ variables: { id, fields } });
            goToView();
        },
        [updateUser, goToView]
    );

    const initialValues = useMemo((): ProfileUpdateFormValues => {
        const values: ProfileUpdateFormValues = {
            id: user.id,
            fullName: user.fullName,
            email: user.email,
            type: user.type,
            mobilePhone: omit(['__typename'], user.mobilePhone),
            profilePicture: user.profilePicture,
        };

        switch (user.__typename) {
            case 'DealerUser':
                return {
                    ...values,
                    business: omit(['__typename'], user.business),
                };

            case 'SaleConsultantUser':
                return {
                    ...values,
                    brand: user.brand,
                };

            default:
                return values;
        }
    }, [user]);

    const formValidator = validators.compose(
        validators.only(
            ({ type }) => type === UserType.Dealer,
            validators.compose(
                validators.requiredNonEmptyString('business.name'),
                validators.requiredNonEmptyString('business.registrationNumber'),
                validators.requiredNonEmptyString('business.address')
            )
        )
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <ProfileUpdateHeader goToView={goToView} />
                    <Box ml={isSmall ? 2 : 0} mr={isSmall ? 2 : 0} position="relative">
                        <ProfileContainer>
                            <ProfileUpdateBody user={user} />
                        </ProfileContainer>
                        {isSmall && <ProfileUpdateFooter goToView={goToView} />}
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default ProfileUpdate;
