import { FormControl, Box, FormLabel, Button } from '@material-ui/core';
import { useField } from 'formik';
import { ReactNode, useRef, useCallback, useMemo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { NameOnlyFileDataFragment } from '../../api';
import useCommonStyles from '../../useCommonStyles';
import { getExtension, getAcceptExtensions } from '../../utilities/file';
import { useAlert } from '../AlertProvider';
import FileList, { FileListProps } from './FileList';

export type AttachmentButtonProps = {
    name: string;
    label?: string;
    buttonText: string;
    icon?: ReactNode | null;
    max?: number;
    maxSize?: number;
    allowedExtensions?: string[];
    disabled?: boolean;
    renderPrefix?: FileListProps['renderPrefix'];
};

const AttachmentButton = ({
    name,
    label,
    buttonText,
    icon,
    max,
    maxSize,
    allowedExtensions,
    renderPrefix,
    disabled,
}: AttachmentButtonProps) => {
    const { t } = useTranslation(['errors']);
    const { show } = useAlert();
    const [{ value: attachments }, , { setValue }] = useField<(File | NameOnlyFileDataFragment)[]>(name);
    const commonStyles = useCommonStyles();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let nextValues = attachments;
            Array.from(event.currentTarget.files).forEach(file => {
                if (max !== undefined && nextValues?.length >= max) {
                    // limit reached
                    return;
                }

                if (maxSize !== undefined && file.size > maxSize) {
                    // display error message
                    show('error', t('errors:largeFileSize', { fileSize: maxSize / 1000 / 1000 }));

                    return;
                }

                if (allowedExtensions !== undefined) {
                    const extension = getExtension(file.name).toLowerCase();

                    if (!allowedExtensions.some(allowedExtension => allowedExtension === extension)) {
                        // display error message
                        show('error', t('errors:invalidFileType'));

                        return;
                    }
                }

                // add the new file
                nextValues = [...nextValues, file];
            });

            // update value
            setValue(nextValues);

            // then reset value
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
        },
        [setValue, attachments, maxSize, max, show, t, allowedExtensions]
    );

    const accept = useMemo(
        () => (allowedExtensions ? getAcceptExtensions(allowedExtensions).join(',') : ''),
        [allowedExtensions]
    );

    return (
        <FormControl fullWidth>
            <input ref={fileInputRef} accept={accept} onChange={onChange} style={{ display: 'none' }} type="file" />
            {label && <FormLabel className={commonStyles.formLabel}>{label}</FormLabel>}
            {attachments?.length > 0 && (
                <Box mb={1}>
                    <FileList
                        files={attachments}
                        removeFile={!disabled ? file => setValue(attachments?.filter(item => item !== file)) : null}
                        renderPrefix={renderPrefix}
                    />
                </Box>
            )}
            <Button
                color="secondary"
                disabled={disabled || (max !== undefined && attachments?.length >= max)}
                onClick={() => fileInputRef.current.click()}
                variant="contained"
            >
                {icon ? (
                    <>
                        {icon} {buttonText}
                    </>
                ) : (
                    buttonText
                )}
            </Button>
        </FormControl>
    );
};

export default AttachmentButton;
