import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRuntimeConfig } from '../../../runtimeConfig';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            bottom: '0px',
            width: '100%',
            padding: '20px',
        },
        version: {
            color: '#aaa !important',
            fontSize: '0.8rem',
        },
    })
);

const Version = () => {
    const { t } = useTranslation(['loginPage']);
    const { version } = useRuntimeConfig();
    const styles = useStyles();

    return (
        <Box className={styles.root}>
            <Typography align="center" className={cs(styles.version)}>
                {t('loginPage:copyright', { version })}
            </Typography>
        </Box>
    );
};

export default Version;
