import { Box, List, ListItemText } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useCurrentUser } from '../../../../Session';
import getMenuListItems from '../getMenuListItems';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: 'auto',
            flex: '1 1 auto',
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '90px',
            paddingLeft: '24px',
            paddingRight: '24px',
            borderBottom: '1px solid #E6E5E5',
        },
        item: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#717073',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        activeColor: {
            color: theme.palette.secondary.main,
        },
    })
);

export type DrawerBodyProps = {
    setOpen: (value: boolean) => void;
};

const DrawerBody = ({ setOpen }: DrawerBodyProps) => {
    const { t } = useTranslation(['homePage']);
    const styles = useStyles();

    const history = useHistory();
    const location = useLocation();
    const user = useCurrentUser();
    const menuItems = useMemo(() => getMenuListItems(user.type), [user.type]);

    const redirect = useCallback(
        (route: string) => () => {
            history.push(route);
            setOpen(true);
        },
        [history, setOpen]
    );

    const getMenuStyles = useCallback(
        (path: string) =>
            location.pathname.toLowerCase() === path.toLowerCase() ? cs(styles.item, styles.activeColor) : styles.item,
        [location.pathname, styles.activeColor, styles.item]
    );

    return (
        <Box className={styles.root}>
            <List className={styles.list} component="nav">
                {menuItems.map(menu => (
                    <Box className={styles.itemContainer} onClick={redirect(menu.path)}>
                        <Box className={getMenuStyles(menu.path)}>
                            <ListItemText primary={t(`homePage:menuBar.${menu.text}`)} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                ))}
                <Box className={styles.itemContainer} onClick={redirect('/settings')}>
                    <Box className={getMenuStyles('/settings')}>
                        <ListItemText primary={t('homePage:menuBar.settings')} />
                        <ChevronRightIcon />
                    </Box>
                </Box>
            </List>
        </Box>
    );
};

export default DrawerBody;
