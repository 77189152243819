import EditSaleTransactionForm, { EditSaleTransactionProps } from './EditSaleTransactionForm';
import { Page, useEditSaleTransactionState } from './EditSalesTransactionStateProvider';
import EditVehiclePrefillForm from './EditVehiclePrefillForm';

const EditSaleTransactionInner = (props: EditSaleTransactionProps) => {
    const {
        state: { currentPage },
    } = useEditSaleTransactionState();

    switch (currentPage) {
        case Page.VehiclePrefill:
            return <EditVehiclePrefillForm />;

        case Page.VehicleForm:
            return <EditSaleTransactionForm {...props} />;

        default:
            return null;
    }
};

export default EditSaleTransactionInner;
