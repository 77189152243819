import { FormControl, FormLabel, FormHelperText, OutlinedInput, OutlinedInputProps } from '@material-ui/core';
import { useField } from 'formik';
import { cloneDeep } from 'lodash/fp';
import { ReactNode } from 'react';
import useCommonStyles from '../../useCommonStyles';

export interface OutlinedInputNumberFieldProps extends Omit<OutlinedInputProps, 'value' | 'onChange'> {
    name: string;
    helperText?: (value: string) => ReactNode;
}

const OutlinedInputNumberField = ({
    name,
    label,
    required,
    helperText,
    inputProps,
    ...props
}: OutlinedInputNumberFieldProps) => {
    const { disabled } = props;
    const [{ value, ...field }, meta] = useField({ name });
    const commonStyles = useCommonStyles();

    const hasError = !!meta.error && meta.touched;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = String(event.target.value);
        const clonedEvent = cloneDeep(event);
        const { maxlength } = inputProps || {};

        // truncate the input value if it exceeds the maxlength
        if (maxlength && inputValue?.length > maxlength) {
            clonedEvent.target.value = inputValue.slice(0, maxlength);
        }

        field.onChange(event);
    };

    return (
        <FormControl fullWidth>
            {label && (
                <FormLabel className={commonStyles.formLabel} disabled={disabled} required={required}>
                    {label}
                </FormLabel>
            )}
            <OutlinedInput
                // by default auto complete is off but props can always override it
                autoComplete="off"
                // spread props
                {...props}
                // the error is defined from what we go in meta data
                error={hasError}
                inputProps={inputProps}
                // provide the value but override null values with undefined
                value={value === null ? undefined : value}
                // then spread the field properties itself
                {...field}
                // handle input change
                onChange={handleInputChange}
                type="number"
            />
            <FormHelperText error={hasError}>{hasError ? meta.error : ''}</FormHelperText>
            {helperText && helperText(value)}
        </FormControl>
    );
};

export default OutlinedInputNumberField;
