import { Box, Grid, Card, CardContent, Typography, Button, CardMedia } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import useCommonStyles from '../../../useCommonStyles';
import usePublic from '../../../utilities/usePublic';
import Transaction from './Transaction';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexWrap: 'wrap',
            display: 'flex',
        },
        card: {
            padding: 0,
            minHeight: '200px',
            display: 'flex',
            backgroundColor: '#F2F2F2',
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            flex: 1,
            '&:last-child': {
                padding: 0,
            },
        },
        mediaInfo: {
            minHeight: '110px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#000000',
            alignItems: 'center',
            paddingLeft: theme.spacing(3),
        },
        profileInfoWrapper: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            minWidth: '100%',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        profileInfo: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
            flex: 1,
        },
        titleWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            flex: 2,
        },
        title: {
            color: theme.palette.secondary.contrastText,
            fontSize: '16px',
            fontWeight: 'bold',
            maxWidth: '140px',
        },
        greetImage: {
            width: '121px',
            height: '98px',
            marginTop: 'auto',
            backgroundSize: 'contain',
        },
        customButton: {
            maxWidth: '293px',
            width: '100%',
        },
        transactionButton: {
            position: 'relative',
            top: '35px',
        },
    })
);

const HomePageBody = () => {
    const { t } = useTranslation(['common', 'homePage']);

    const history = useHistory();
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const user = useCurrentUser();

    const image = usePublic('assets/images/greet.png');

    return (
        <Grid className={styles.root} spacing={5} container>
            <Grid lg={4} xs={12} item>
                <Card className={styles.card} elevation={0}>
                    <CardContent className={styles.cardContent}>
                        <Box className={styles.mediaInfo}>
                            <Box className={styles.titleWrapper}>
                                <Typography className={styles.title}>
                                    {t('homePage:welcomeBackUser', { name: user.fullName })}
                                </Typography>
                            </Box>
                            <CardMedia className={styles.greetImage} image={image} />
                        </Box>
                        <Grid className={styles.profileInfoWrapper} xs={10} item>
                            <Box className={styles.profileInfo}>
                                <Button
                                    className={cs(
                                        styles.customButton,
                                        commonStyles.removeBoxShadow,
                                        commonStyles.defaultBorderRadius
                                    )}
                                    color="secondary"
                                    onClick={() => history.push('/profile')}
                                    size="large"
                                    variant="contained"
                                >
                                    {t('homePage:button.viewProfile')}
                                </Button>
                            </Box>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {/* <Grid lg={4} xs={12} item>
                <Transaction title={t('homePage:purchaseTransactions')}>
                    <Button
                        className={cs(
                            styles.customButton,
                            commonStyles.removeBoxShadow,
                            commonStyles.defaultBorderRadius
                        )}
                        color="secondary"
                        onClick={() => history.push('/purchaseTransactions')}
                        size="large"
                        variant="contained"
                    >
                        {t('homePage:button.viewPurchaseTransactions')}
                    </Button>
                </Transaction>
            </Grid> */}
            <Grid lg={4} xs={12} item>
                <Transaction title={t('homePage:salesTransactions')}>
                    <Button
                        className={cs(
                            styles.transactionButton,
                            styles.customButton,
                            commonStyles.removeBoxShadow,
                            commonStyles.defaultBorderRadius
                        )}
                        color="secondary"
                        onClick={() => history.push('/salesTransactions')}
                        size="large"
                        variant="contained"
                    >
                        {t('homePage:button.viewSalesTransactions')}
                    </Button>
                </Transaction>
            </Grid>
        </Grid>
    );
};

export default HomePageBody;
