import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { omit } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    CreateBiddingMutationVariables,
    useAddSalesTransactionVehicleDiagramCommentMutation,
    useCreateBiddingMutation,
    useCreateSaleTransactionMutation,
    useUploadSalesTransactionPhotoMutation,
} from '../../../api';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import { CreateBiddingSessionBody } from '../../BiddingSessions/components/CreateBiddingSession';
import CreateSaleTransactionHeader from './CreateSaleTransactionHeader';
import { Page, useCreateSalesTransactionState } from './CreateSalesTransactionStateProvider';
import getOfferSessionName from './utilities/getOfferSessionName';
import { CreateSaleTransactionFormValues } from '.';

export type FormValues = Pick<
    CreateBiddingMutationVariables['fields'],
    'sessionName' | 'notes' | 'startPeriod' | 'endPeriod'
> & {
    saleTransactions: CreateSaleTransactionFormValues[];
};

const CreateOfferSessionForm = () => {
    const { t } = useTranslation(['biddingSessionsPage']);
    const history = useHistory();
    const [createSaleTransaction] = useCreateSaleTransactionMutation();
    const [uploadSalesTransactionPhotoMutation] = useUploadSalesTransactionPhotoMutation();
    const [addVehicleDiagramComment] = useAddSalesTransactionVehicleDiagramCommentMutation();
    const [createBidding] = useCreateBiddingMutation();

    const basicStyles = useBasicStyle();

    const {
        state: { formValue, comments },
        actions,
    } = useCreateSalesTransactionState();

    const formValidator = useMemo(
        () =>
            validators.compose(
                validators.requiredString('sessionName'),
                validators.requiredDate('startPeriod', t('biddingSessionsPage:session.invalidDate')),
                validators.requiredDate('endPeriod', t('biddingSessionsPage:session.invalidDate')),
                validators.validDateBeforeReferenceField(
                    'startPeriod',
                    'endPeriod',
                    t('biddingSessionsPage:session.invalidDate')
                )
            ),
        [t]
    );

    const onSubmit = useHandleError(
        async (values: FormValues) => {
            const { photos, frontPagePhoto, ...fieldValue } = formValue;

            // Creating sale transaction
            const {
                data: {
                    createSaleTransaction: { id },
                },
            } = await createSaleTransaction({
                variables: { fields: fieldValue },
            });

            // Creating bidding session
            await createBidding({
                variables: {
                    fields: {
                        ...omit('saleTransactions', values),
                        saleTransactionIds: [id],
                    },
                },
            });

            // Uploading photos
            await Promise.all([
                ...photos.map(async file => {
                    await uploadSalesTransactionPhotoMutation({
                        variables: {
                            salesTransactionId: id,
                            file,
                            isFrontPagePhoto: frontPagePhoto === file,
                        },
                    });
                }),
            ]);

            // need to save comment sequently
            for (const comment of comments) {
                // eslint-disable-next-line no-await-in-loop
                await addVehicleDiagramComment({
                    variables: {
                        fields: comment,
                        salesTransactionId: id,
                    },
                });
            }

            history.goBack();
        },
        [history, actions, comments, createSaleTransaction, uploadSalesTransactionPhotoMutation]
    );

    const initialValues: FormValues = useMemo(
        () => ({
            sessionName: getOfferSessionName(formValue.vehicle),
            endPeriod: dayjs().add(1, 'd').toDate(),
            startPeriod: dayjs().toDate(),
            saleTransactions: [formValue],
        }),
        [formValue]
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <CreateSaleTransactionHeader currentPage={Page.OfferSession} />
                    <Box className={basicStyles.mainDesktop}>
                        <CreateBiddingSessionBody />
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default CreateOfferSessionForm;
