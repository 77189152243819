import { Box, Typography, useMediaQuery, Link, Paper, Button } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MobileAppBar from '../../../components/MobileAppBar';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';
import { Page, useEditSaleTransactionState } from './EditSalesTransactionStateProvider';

export type EditSaleTransactionHeaderProps = {
    currentPage: Page;
    goToView?: () => void;
};

const useHeaderStyles = makeStyles(theme => ({
    header: {
        marginBottom: 0,
        paddingBottom: 0,
    },
}));

const useHeaderLabels = (currentPage: Page) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);

    return useMemo(() => {
        switch (currentPage) {
            case Page.VehiclePrefill:
                return {
                    back: t('common:portalBackButton'),
                    title: t('saleTransactionsPage:importFromOneMotoringTitle'),
                    submit: t('common:next'),
                };

            case Page.VehicleForm:
                return {
                    back: t('common:cancel'),
                    title: t('saleTransactionsPage:editTitle'),
                    submit: t('common:save'),
                };

            default:
                return {
                    back: t('common:cancel'),
                    title: t('saleTransactionsPage:editTitle'),
                    submit: t('common:save'),
                };
        }
    }, [t, currentPage]);
};

const EditSaleTransactionHeader = ({ currentPage, goToView }: EditSaleTransactionHeaderProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const headerStyles = useHeaderStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { isSubmitting, isValid, dirty } = useFormikContext();
    const disabledSubmit = useMemo(
        () => isSubmitting || !isValid || (currentPage === Page.VehiclePrefill && !dirty),
        [isSubmitting, isValid, dirty, currentPage]
    );

    const labels = useHeaderLabels(currentPage);

    const { actions } = useEditSaleTransactionState();

    const onBack = useCallback(() => {
        switch (currentPage) {
            case Page.VehiclePrefill:
                actions.setCurrentPage(Page.VehicleForm);
                break;

            case Page.VehicleForm: {
                if (goToView) {
                    goToView();
                }
                break;
            }

            default:
                break;
        }
    }, [currentPage, actions, goToView]);

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box zIndex={1}>
                    <Link className={commonStyles.primary2} component="button" onClick={onBack} underline="none">
                        {labels.back}
                    </Link>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" pr={2} width="100%">
                    <Typography className={commonStyles.fontWeightBold}>{labels.title}</Typography>
                </Box>
                <Box ml="auto" zIndex={1}>
                    <Link
                        className={commonStyles.primary2}
                        component="button"
                        disabled={disabledSubmit}
                        type="submit"
                        underline="none"
                    >
                        {labels.submit}
                    </Link>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <>
            <Paper
                className={cs(basicStyles.backPaper, basicStyles.lightBoxShadow, basicStyles.stickyHeader)}
                elevation={0}
            >
                <Link className={cs(basicStyles.linkBack)} component="a" onClick={onBack}>
                    <ArrowBackIosIcon fontSize="small" /> {labels.back}
                </Link>

                <Box className={basicStyles.rightToolbar} ml="auto">
                    <Box className={cs([commonStyles.buttonGroup])}>
                        {currentPage !== Page.VehiclePrefill && (
                            <Button color="secondary" onClick={goToView} variant="outlined">
                                {t('common:cancel')}
                            </Button>
                        )}

                        <Button color="secondary" disabled={disabledSubmit} type="submit" variant="contained">
                            {labels.submit}
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Box
                alignItems="center"
                className={cs([basicStyles.main, commonStyles.header, headerStyles.header])}
                display="flex"
            >
                <Typography className={commonStyles.title}>{labels.title}</Typography>
            </Box>
        </>
    );
};

export default EditSaleTransactionHeader;
