import { Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import InfoIcon from '@material-ui/icons/Info';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../Session';
import { SaleTransactionStage, UserType } from '../../api';
import { SaleTransactionTabValue } from './ViewSaleTransaction/ViewSaleTransactionBody';
import CarIcon from '../../icons/carIcon.svg';

export type DesktopMenuTabsProps = {
    a11yProps: (index: SaleTransactionTabValue) => object;
    value: SaleTransactionTabValue;
    setValue: (value: SaleTransactionTabValue) => void;
    stage: SaleTransactionStage;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: '1px solid #C4C4C4',
            marginBottom: theme.spacing(3),
        },
    })
);

type MenuTabsProps = {
    userType: UserType;
    t: TFunction;
    a11yProps: (index: SaleTransactionTabValue) => object;
    styles: ClassNameMap;
    value: SaleTransactionTabValue;
    setValue: (value: SaleTransactionTabValue) => void;
};

const DraftSaleDeskopMenuTab = ({ t, a11yProps, styles, value, setValue }: Omit<MenuTabsProps, 'userType'>) => (
    <Tabs
        classes={{ root: styles.root }}
        indicatorColor="secondary"
        onChange={(_, value) => setValue(value)}
        scrollButtons="auto"
        textColor="secondary"
        value={value}
    >
        <Tab
            icon={<CarIcon className="MuiSvgIcon-root" />}
            label={t('saleTransactionsPage:menu.vehicle')}
            value={SaleTransactionTabValue.Vehicle}
            {...a11yProps(SaleTransactionTabValue.Vehicle)}
        />
    </Tabs>
);

const NonDraftSaleDeskopMenuTab = ({ userType, t, a11yProps, styles, value, setValue }: MenuTabsProps) => (
    <Tabs
        classes={{ root: styles.root }}
        indicatorColor="secondary"
        onChange={(_, value) => setValue(value)}
        scrollButtons="auto"
        textColor="secondary"
        value={value}
    >
        {userType !== UserType.Dealer && (
            <Tab
                icon={<InfoIcon />}
                label={t('saleTransactionsPage:menu.transaction')}
                value={SaleTransactionTabValue.Transaction}
                {...a11yProps(SaleTransactionTabValue.Transaction)}
            />
        )}
        <Tab
            icon={<CarIcon className="MuiSvgIcon-root" />}
            label={t('saleTransactionsPage:menu.vehicle')}
            value={SaleTransactionTabValue.Vehicle}
            {...a11yProps(SaleTransactionTabValue.Vehicle)}
        />
        <Tab
            icon={<InfoIcon />}
            label={t('saleTransactionsPage:menu.bidding')}
            value={SaleTransactionTabValue.Bidding}
            {...a11yProps(SaleTransactionTabValue.Bidding)}
        />
    </Tabs>
);

const DektopMenuTabs = ({
    stage,
    userType,
    t,
    a11yProps,
    styles,
    value,
    setValue,
}: MenuTabsProps & { stage: SaleTransactionStage }) => {
    switch (stage) {
        case SaleTransactionStage.Drafted:
            return (
                <DraftSaleDeskopMenuTab a11yProps={a11yProps} setValue={setValue} styles={styles} t={t} value={value} />
            );

        default:
            return (
                <NonDraftSaleDeskopMenuTab
                    a11yProps={a11yProps}
                    setValue={setValue}
                    styles={styles}
                    t={t}
                    userType={userType}
                    value={value}
                />
            );
    }
};

const DesktopMenuTabs = ({ value, setValue, a11yProps, stage }: DesktopMenuTabsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const styles = useStyles();

    const currentUser = useCurrentUser();

    return (
        <DektopMenuTabs
            a11yProps={a11yProps}
            setValue={setValue}
            stage={stage}
            styles={styles}
            t={t}
            userType={currentUser.type}
            value={value}
        />
    );
};

export default DesktopMenuTabs;
