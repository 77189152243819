import { Box, Grid, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { cardDefaultImage } from '../../../../../../server/schema/constants';
import { BiddingStatus, FullBiddingDataFragment, PreviewSaleTransactionDataFragment } from '../../../../../api';
import useCommonStyles from '../../../../../useCommonStyles';
import usePublic from '../../../../../utilities/usePublic';
import useVehicleLogo from '../../../../../utilities/useVehicleLogo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 0,
        },
        header: {
            maxHeight: '170px',
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            color: '#717073',
        },
        label: {
            fontSize: '14px',
            gap: '6px',
            display: 'flex',
        },
        info: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        sessionInfo: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        biddingWrapper: {
            borderBottom: '1px solid #EDEDED',
            padding: theme.spacing(1),
        },
        carImage: {
            height: theme.spacing(30),
            borderTopRightRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
            backgroundColor: '#B3B3B3',
        },
        vehicleMakeHeader: {
            color: '#000',
            fontSize: '16px',
            fontWeight: 'bold',
        },
        vehicleNumber: {
            color: '#000',
            fontSize: '14px',
        },
        upcomingStage: {
            backgroundColor: theme.palette.secondary.main,
        },
        pastStage: {
            backgroundColor: 'gray',
        },
        currentStage: {
            backgroundColor: 'green',
        },
        carLogo: {
            marginLeft: 'auto',
            height: '3rem',
        },
    })
);

export type BiddingSessionsItemProps = {
    session: FullBiddingDataFragment;
    saleTransaction: PreviewSaleTransactionDataFragment;
};

const BiddingSessionsDetailItem = ({ saleTransaction, session }: BiddingSessionsItemProps) => {
    const { t } = useTranslation(['common', 'biddingSessionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const defaultImage = usePublic(cardDefaultImage);
    const carLogo = useVehicleLogo(saleTransaction.vehicle.make);

    const getStatusText = () => {
        // for different status text
        switch (session.status) {
            case BiddingStatus.Current:
                return (
                    <Typography
                        className={cs(
                            commonStyles.commonTransactionStatus,
                            commonStyles.transactionStatus,
                            styles.currentStage
                        )}
                    >
                        {t('biddingSessionsPage:status.current')}
                    </Typography>
                );

            case BiddingStatus.Past:
                return (
                    <Typography
                        className={cs(
                            commonStyles.commonTransactionStatus,
                            commonStyles.transactionStatus,
                            styles.pastStage
                        )}
                    >
                        {t('biddingSessionsPage:status.past')}
                    </Typography>
                );

            case BiddingStatus.Upcoming:
                return (
                    <Typography
                        className={cs(
                            commonStyles.commonTransactionStatus,
                            commonStyles.transactionStatus,
                            styles.upcomingStage
                        )}
                    >
                        {t('biddingSessionsPage:status.upcoming')}
                    </Typography>
                );

            default:
                return null;
        }
    };

    const history = useHistory();

    return (
        <Grid lg={3} md={6} sm={12} xs={12} item>
            <Card elevation={0}>
                <CardActionArea onClick={() => history.push(`/salesTransactions/${saleTransaction.id}`)}>
                    <CardContent className={styles.content}>
                        <Box className={styles.header}>
                            <CardMedia
                                className={styles.carImage}
                                image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                            />
                        </Box>

                        <Box className={styles.body}>
                            <Grid spacing={3} container>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Box>
                                            <Box display="flex">
                                                <Typography className={styles.vehicleMakeHeader}>
                                                    {saleTransaction.vehicle.make} {saleTransaction.vehicle.model}
                                                </Typography>
                                                {getStatusText()}
                                            </Box>
                                            <Box>
                                                <Typography className={styles.vehicleNumber}>
                                                    {saleTransaction.vehicle.number}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box marginLeft="auto">
                                            <Box display="flex">
                                                <img alt="carLogo" className={commonStyles.vehicleLogo} src={carLogo} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box mb="10px">
                                        <Typography className={cs(styles.label)} color="secondary">
                                            {t('biddingSessionsPage:sessionDetails.title')}
                                        </Typography>
                                    </Box>
                                    <Box display="flex">
                                        <Box>
                                            <Typography className={cs(commonStyles.secondary2, styles.label)}>
                                                <Box component="span">
                                                    {t('biddingSessionsPage:sessionDetails.bidOpeningOn')}
                                                </Box>
                                                <Box component="span">
                                                    {t('common:formats.longDateTime', {
                                                        date: new Date(session.startPeriod),
                                                    })}
                                                </Box>
                                            </Typography>
                                            <Typography className={cs(commonStyles.secondary2, styles.label)}>
                                                <Box component="span">
                                                    {t('biddingSessionsPage:sessionDetails.bidClosingOn')}
                                                </Box>
                                                <Box component="span">
                                                    {t('common:formats.longDateTime', {
                                                        date: new Date(session.endPeriod),
                                                    })}
                                                </Box>
                                            </Typography>
                                        </Box>
                                        <ChevronRightIcon color="secondary" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default BiddingSessionsDetailItem;
