import { Card, CardContent, Box, Typography, CardMedia, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { cardDefaultImage } from '../../../../../server/schema/constants';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import usePublic from '../../../../utilities/usePublic';
import usePurchaseTransactionStatus from '../../../../utilities/usePurchaseTransactionStatus';
import usePurchaseTransactionStatusStyles from '../../../../utilities/usePurchaseTransactionStatusStyles';
import useVehicleLogo from '../../../../utilities/useVehicleLogo';

export type VehicleInfoProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        registrationNo: {
            color: 'rgb(0, 0, 0, 0.8)',
        },
        carImage: {
            height: theme.spacing(30),
            marginBottom: theme.spacing(2),
            borderRadius: theme.spacing(1),
            backgroundColor: '#B3B3B3',
        },
    })
);

const VehicleInfo = ({ purchaseTransaction }: VehicleInfoProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const carLogo = useVehicleLogo(purchaseTransaction.vehicle.make);
    const defaultImage = usePublic(cardDefaultImage);
    const purchaseTransactionStatus = usePurchaseTransactionStatus(purchaseTransaction.stage);
    const transactionStyles = usePurchaseTransactionStatusStyles(purchaseTransaction.stage);

    if (isSmall) {
        return (
            <Card className={commonStyles.card}>
                <CardContent>
                    <Box alignItems="center" display="flex">
                        <Box>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.primary, commonStyles.fontWeightBold)}>
                                    {purchaseTransaction.vehicle.make} {purchaseTransaction.vehicle.model}
                                </Typography>
                                <Typography
                                    className={
                                        isSmall
                                            ? cs(
                                                  commonStyles.commonTransactionStatus,
                                                  commonStyles.transactionStatus2,
                                                  transactionStyles
                                              )
                                            : cs(commonStyles.commonTransactionStatus, commonStyles.transactionStatus)
                                    }
                                >
                                    {purchaseTransactionStatus}
                                </Typography>
                            </Box>
                            <Typography className={cs(styles.registrationNo, commonStyles.fontWeightLight)}>
                                {purchaseTransaction.vehicle.number}
                            </Typography>
                        </Box>
                        <img alt="carLogo" className={commonStyles.vehicleLogo} src={carLogo} />
                    </Box>
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            <CardMedia
                className={styles.carImage}
                image={purchaseTransaction.frontPagePhoto?.signedUrl || defaultImage}
            />
            <Box alignItems="center" display="flex">
                <Box>
                    <Box display="flex">
                        <Typography className={cs(commonStyles.primary, commonStyles.fontWeightBold)}>
                            {purchaseTransaction.vehicle.make} {purchaseTransaction.vehicle.model}
                        </Typography>
                        <Typography
                            className={cs(commonStyles.commonTransactionStatus, commonStyles.transactionStatus)}
                        >
                            {purchaseTransactionStatus}
                        </Typography>
                    </Box>
                    <Typography className={cs(styles.registrationNo, commonStyles.fontWeightLight)}>
                        {purchaseTransaction.vehicle.number}
                    </Typography>
                </Box>
                <img alt="carLogo" className={commonStyles.vehicleLogo} src={carLogo} />
            </Box>
        </>
    );
};

export default VehicleInfo;
