import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SaleTransactionStage, UserType } from '../api';

const useSalesTransactionStatus = (
    transactionStatus: SaleTransactionStage,
    userType?: UserType,
    isAwarded?: boolean
) => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return useMemo(() => {
        switch (transactionStatus) {
            case SaleTransactionStage.BiddingOpen:
                return t('saleTransactionsPage:filter.biddingOpen');

            case SaleTransactionStage.Closed:
                return t('saleTransactionsPage:filter.closed');

            case SaleTransactionStage.Completed: {
                if (userType === UserType.Dealer) {
                    return isAwarded
                        ? t('saleTransactionsPage:dealerStatus.awarded')
                        : t('saleTransactionsPage:dealerStatus.notAwarded');
                }

                return t('saleTransactionsPage:filter.completed');
            }

            case SaleTransactionStage.ForAwarding:
                return t('saleTransactionsPage:filter.forAwarding');

            case SaleTransactionStage.ForHandover:
                return t('saleTransactionsPage:filter.forHandover');

            case SaleTransactionStage.New:
                return t('saleTransactionsPage:filter.new');

            case SaleTransactionStage.PendingApproval:
                return t('saleTransactionsPage:filter.pendingApproval');

            case SaleTransactionStage.UpcomingBidding:
                return t('saleTransactionsPage:filter.upcomingBidding');

            case SaleTransactionStage.Cancelled:
                return t('saleTransactionsPage:filter.cancelled');

            case SaleTransactionStage.Drafted:
                return t('saleTransactionsPage:filter.drafted');

            case SaleTransactionStage.Rejected:
                return t('saleTransactionsPage:filter.rejected');

            default:
                return '';
        }
    }, [t, transactionStatus, isAwarded, userType]);
};

export default useSalesTransactionStatus;
