import { Grid, Box, CardMedia, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import TabPanel from '../../../../components/TabPanel';
import { MobileMenuTabs, DesktopMenuTabs } from '../../components';
import HandoverTab from './HandoverTab';
import TransactionTab from './TransactionTab';
import ValuationTab from './ValuationTab';
import VehicleInfo from './VehicleInfo';
import VehicleTab from './VehicleTab';

export type ViewPurchaseTransactionBodyProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    showConfirmDialog: () => void;
    goToVehicleDetails: () => void;
    openCompleteDrawer: () => void;
};

const a11yProps = (index: string) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(8),
            },
        },
        body: {
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
        },
        carImage: {
            height: theme.spacing(30),
            marginBottom: theme.spacing(2),
            borderRadius: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                borderRadius: theme.spacing(0),
            },
            backgroundColor: '#B3B3B3',
        },
    })
);

const ViewPurchaseTransactionBody = ({
    purchaseTransaction,
    showConfirmDialog,
    goToVehicleDetails,
    openCompleteDrawer,
}: ViewPurchaseTransactionBodyProps) => {
    const styles = useStyles();
    const [value, setValue] = useState('transaction');

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0}>
                {isSmall && purchaseTransaction.frontPagePhoto && (
                    <CardMedia className={styles.carImage} image={purchaseTransaction.frontPagePhoto.signedUrl} />
                )}
                <Box className={styles.body} pl={isSmall ? 1 : 0} pr={isSmall ? 1 : 0}>
                    <Grid spacing={2} container>
                        <Grid lg={5} md={5} sm={12} xs={12} item>
                            <Box pl={isSmall ? 2 : 0} pr={isSmall ? 2 : 0}>
                                <VehicleInfo purchaseTransaction={purchaseTransaction} />
                            </Box>
                        </Grid>
                        <Grid lg={7} md={7} sm={12} xs={12} item>
                            {!isSmall && <DesktopMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />}
                            <Box pl={isSmall ? 0.5 : 0} pr={isSmall ? 0.5 : 0}>
                                <TabPanel index="transaction" value={value}>
                                    <TransactionTab purchaseTransaction={purchaseTransaction} />
                                </TabPanel>
                                <TabPanel index="vehicle" value={value}>
                                    <VehicleTab purchaseTransaction={purchaseTransaction} />
                                </TabPanel>
                                <TabPanel index="valuation" value={value}>
                                    <ValuationTab
                                        goToVehicleDetails={goToVehicleDetails}
                                        openCompleteDrawer={openCompleteDrawer}
                                        purchaseTransaction={purchaseTransaction}
                                        showConfirmDialog={showConfirmDialog}
                                    />
                                </TabPanel>
                                <TabPanel index="handover" value={value}>
                                    <HandoverTab purchaseTransaction={purchaseTransaction} />
                                </TabPanel>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {isSmall && <MobileMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />}
        </>
    );
};

export default ViewPurchaseTransactionBody;
