import { get } from 'lodash/fp';
import { useMemo } from 'react';
import validators from '../../../utilities/validators';

const useVehicleValidation = (basicOnly: boolean = false, prefix: string = 'vehicle') =>
    useMemo(
        () =>
            validators.nest(
                prefix,
                validators.compose(
                    validators.requiredString('ownerId'),
                    validators.requiredString('number'),
                    validators.requiredNumber('mileage'),
                    validators.only(
                        () => !basicOnly,
                        validators.compose(
                            validators.requiredDate('intendedDeregistrationDate'),
                            validators.requiredString('make'),
                            validators.requiredString('model'),
                            validators.requiredString('primaryColour'),
                            validators.requiredNumber('manufacturingYear'),
                            validators.requiredString('engineNumber'),
                            validators.requiredString('chassisNumber'),
                            validators.requiredString('maximumPowerOutput'),
                            validators.requiredNumber('openMarketValue'),
                            validators.requiredDate('originalRegistrationDate'),
                            validators.requiredDate('firstRegistrationDate'),
                            validators.requiredNumber('transferCount'),
                            validators.requiredNumber('actualARFPaid'),
                            validators.requiredDate('coeExpiryDate'),
                            validators.requiredString('coeCategory'),
                            validators.requiredNumber('coePeriodYear'),
                            validators.only(
                                values => get('parfEligibility', values) === true,
                                validators.requiredString('parfEligibilityDate')
                            )
                        )
                    )
                )
            ),
        [prefix, basicOnly]
    );

export default useVehicleValidation;
