import { Grid, Typography, Link, Button, FormControl, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    UserType,
    useUpdateTransactionToInitialValuationMutation,
    useUpdateTransactionToPendingAcceptanceMutation,
    useUpdateTransactionToHandoverMutation,
    useUpdateTransactionToCancelMutation,
    useUpdateTransactionToPendingApprovalMutation,
    useUpdateTransactionToFinalValuationMutation,
    PurchaseTransactionStage,
    FullPurchaseTransactionDataFragment,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import FileList from '../../../../components/attachments/FileList';
import useCommonStyles from '../../../../useCommonStyles';
import ErrorDialog from './ErrorDialog';
import ValuationHistoric from './ValuationHistoric';

export type ValuationTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    showConfirmDialog: () => void;
    goToVehicleDetails: () => void;
    openCompleteDrawer: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorText: {
            color: theme.palette.error.main,
        },
        cancelTransactionLabel: {
            color: '#A5A5A5',
        },
    })
);

const ValuationTab = ({
    purchaseTransaction,
    showConfirmDialog,
    goToVehicleDetails,
    openCompleteDrawer,
}: ValuationTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const [sendForAcceptanceErrors, setSendForAcceptanceErrors] = useState<string[] | null>(null);
    const [validToChangePendingApproval, setValidToChangePendingApproval] = useState<string[] | null>(null);

    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const currentUser = useCurrentUser();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const variables = { transactionId: purchaseTransaction.id };
    const [updateToInitialValuation] = useUpdateTransactionToInitialValuationMutation({ variables });
    const [updateToPendingAcceptance] = useUpdateTransactionToPendingAcceptanceMutation({ variables });
    const [updateToFinalValuation] = useUpdateTransactionToFinalValuationMutation({ variables });
    const [updateToPendingApproval] = useUpdateTransactionToPendingApprovalMutation({ variables });
    const [updateToHandover] = useUpdateTransactionToHandoverMutation({ variables });
    const [updateToCancel] = useUpdateTransactionToCancelMutation({ variables });

    const onUpdateToInitialValuation = useCallback(() => updateToInitialValuation(), [updateToInitialValuation]);
    const onUpdateToFinalValuation = useCallback(() => updateToFinalValuation(), [updateToFinalValuation]);
    const onUpdateToHandover = useCallback(() => updateToHandover(), [updateToHandover]);
    const onUpdateToCancel = useCallback(() => updateToCancel(), [updateToCancel]);

    const onUpdateToPendingAcceptance = useCallback(() => {
        if (purchaseTransaction.valuation.latestValuation) {
            updateToPendingAcceptance();
            setSendForAcceptanceErrors(null);
        } else {
            setSendForAcceptanceErrors([t('purchaseTransactionsPage:valuation.latestValuation')]);
        }
    }, [purchaseTransaction.valuation.latestValuation, t, updateToPendingAcceptance]);

    const onUpdateToPendingApproval = useCallback(() => {
        let isValidToChangePendingApproval = true;
        const fields: string[] = [];

        if (!purchaseTransaction.vehicle.purchaseAgreementNumber) {
            isValidToChangePendingApproval = false;
            fields.push(t('purchaseTransactionsPage:vehicle.purchaseAgreementNumber'));
        }

        if (!purchaseTransaction.handover.vpaAttachment) {
            isValidToChangePendingApproval = false;
            fields.push(t('purchaseTransactionsPage:handover.vpaAttachment'));
        }

        if (!(purchaseTransaction.valuation.photos && purchaseTransaction.valuation.photos.length > 0)) {
            isValidToChangePendingApproval = false;
            fields.push(t('purchaseTransactionsPage:valuation.noPhotos'));
        }

        if (!purchaseTransaction.handover.handoverLocation) {
            isValidToChangePendingApproval = false;
            fields.push(t('purchaseTransactionsPage:handover.handoverLocation'));
        }

        if (isValidToChangePendingApproval) {
            updateToPendingApproval();
            setValidToChangePendingApproval(null);
        } else {
            setValidToChangePendingApproval(fields);
        }
    }, [
        purchaseTransaction.handover.handoverLocation,
        purchaseTransaction.handover.vpaAttachment,
        purchaseTransaction.valuation.photos,
        purchaseTransaction.vehicle.purchaseAgreementNumber,
        t,
        updateToPendingApproval,
    ]);

    const useCardDesignForAccordion = !isSmall;

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.valuation')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.valuation?.initialValuation
                                        ? purchaseTransaction.valuation?.initialValuation.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:valuation.initialValuation')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.valuation?.latestValuation
                                        ? purchaseTransaction.valuation?.latestValuation.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:valuation.latestValuation')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.valuation?.latestValuation &&
                                    purchaseTransaction.stage === PurchaseTransactionStage.Completed
                                        ? purchaseTransaction.valuation?.latestValuation.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:valuation.actualValuation')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.valuation?.valuatedBy
                                        ? purchaseTransaction.valuation?.valuatedBy.fullName
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:valuation.valuatedBy')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.valuation?.generalCondition
                                        ? purchaseTransaction.valuation?.generalCondition
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:valuation.generalCondition')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    <Link
                                        className={cs(commonStyles.autoMarginLeft, commonStyles.displayFlex)}
                                        color="secondary"
                                        onClick={goToVehicleDetails}
                                        underline="none"
                                    >
                                        {t('purchaseTransactionsPage:seeMore')}
                                        <ChevronRightIcon fontSize="small" />
                                    </Link>
                                }
                                label={t('purchaseTransactionsPage:valuation.vehicleDiagram')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.valuation.photos.length > 0 ? (
                                        <FileList files={purchaseTransaction.valuation.photos} />
                                    ) : (
                                        <Typography>{t('purchaseTransactionsPage:valuation.noPhotos')}</Typography>
                                    )
                                }
                                display="block"
                                label={t('purchaseTransactionsPage:valuation.photos')}
                                mt={2}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>

            {(currentUser.type === UserType.ValuationTeam || currentUser.type === UserType.Approver) && (
                <Grid xs={12} item>
                    <ValuationHistoric purchaseTransaction={purchaseTransaction} />
                </Grid>
            )}

            {currentUser.type === UserType.ValuationTeam && (
                <>
                    {/* Only show for initial valuation
                        stage === 'Initial Valuation'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.InitialValuation && (
                        <>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(
                                            commonStyles.removeBoxShadow,
                                            commonStyles.defaultBorderRadius,
                                            commonStyles.primaryButtonBorder
                                        )}
                                        color="primary"
                                        onClick={onUpdateToPendingAcceptance}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:button.sendForAcceptance')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <ErrorDialog
                                fields={sendForAcceptanceErrors}
                                onClose={() => setSendForAcceptanceErrors(null)}
                            />
                        </>
                    )}

                    {/* Only show for Final Valuation
                        stage === 'Final Valuation'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.FinalValuation && (
                        <>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(
                                            commonStyles.removeBoxShadow,
                                            commonStyles.defaultBorderRadius,
                                            commonStyles.primaryButtonBorder
                                        )}
                                        color="primary"
                                        onClick={onUpdateToPendingApproval}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:button.requestApproval')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <ErrorDialog
                                fields={validToChangePendingApproval}
                                onClose={() => setValidToChangePendingApproval(null)}
                            />
                        </>
                    )}

                    {/* Only show for Handover
                        stage === 'Handover'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.Handover && (
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={cs(
                                        commonStyles.removeBoxShadow,
                                        commonStyles.defaultBorderRadius,
                                        commonStyles.primaryButtonBorder
                                    )}
                                    color="primary"
                                    onClick={openCompleteDrawer}
                                    size="large"
                                    variant="contained"
                                >
                                    {t('purchaseTransactionsPage:button.complete')}
                                </Button>
                            </FormControl>
                        </Grid>
                    )}
                </>
            )}

            {purchaseTransaction.stage === PurchaseTransactionStage.PendingAcceptance &&
                purchaseTransaction.saleConsultant?.id === currentUser.id && (
                    <>
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={cs(
                                        commonStyles.removeBoxShadow,
                                        commonStyles.defaultBorderRadius,
                                        commonStyles.primaryButtonBorder
                                    )}
                                    color="primary"
                                    onClick={showConfirmDialog}
                                    size="large"
                                    variant="contained"
                                >
                                    {t('purchaseTransactionsPage:button.acceptValuation')}
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={cs(commonStyles.defaultBorderRadius, commonStyles.primaryButtonBorder)}
                                    color="primary"
                                    onClick={onUpdateToInitialValuation}
                                    size="large"
                                    variant="outlined"
                                >
                                    {t('purchaseTransactionsPage:button.requestRequote')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </>
                )}

            {currentUser.type === UserType.ValuationTeam &&
                ![
                    PurchaseTransactionStage.Handover,
                    PurchaseTransactionStage.Completed,
                    PurchaseTransactionStage.Cancelled,
                ].includes(purchaseTransaction.stage) && (
                    <Grid
                        lg={
                            [
                                PurchaseTransactionStage.InitialValuation,
                                PurchaseTransactionStage.FinalValuation,
                            ].includes(purchaseTransaction.stage)
                                ? 6
                                : 12
                        }
                        md={
                            [
                                PurchaseTransactionStage.InitialValuation,
                                PurchaseTransactionStage.FinalValuation,
                            ].includes(purchaseTransaction.stage)
                                ? 6
                                : 12
                        }
                        sm={12}
                        xs={12}
                        item
                    >
                        <FormControl fullWidth>
                            <Button
                                className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                classes={{ label: styles.cancelTransactionLabel }}
                                onClick={onUpdateToCancel}
                                size="large"
                                variant="contained"
                            >
                                {t('purchaseTransactionsPage:button.cancelTransaction')}
                            </Button>
                        </FormControl>
                    </Grid>
                )}

            {currentUser.type === UserType.Approver && (
                <>
                    {/* Only show for Pending Approval
                        stage === 'Pending Approval'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.PendingApproval && (
                        <>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(
                                            commonStyles.removeBoxShadow,
                                            commonStyles.defaultBorderRadius,
                                            commonStyles.primaryButtonBorder
                                        )}
                                        color="primary"
                                        onClick={onUpdateToHandover}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:button.approve')}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(
                                            commonStyles.defaultBorderRadius,
                                            commonStyles.primaryButtonBorder
                                        )}
                                        color="primary"
                                        onClick={onUpdateToFinalValuation}
                                        size="large"
                                        variant="outlined"
                                    >
                                        {t('purchaseTransactionsPage:button.reject')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    {/* Only show for Final Valuation
                        stage === 'Final Valuation'
                    */}
                    {purchaseTransaction.stage === PurchaseTransactionStage.FinalValuation && (
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    className={cs(
                                        commonStyles.removeBoxShadow,
                                        commonStyles.defaultBorderRadius,
                                        commonStyles.primaryButtonBorder
                                    )}
                                    color="primary"
                                    onClick={onUpdateToPendingApproval}
                                    size="large"
                                    variant="contained"
                                >
                                    {t('purchaseTransactionsPage:button.requestApproval')}
                                </Button>
                            </FormControl>
                        </Grid>
                    )}

                    {purchaseTransaction.stage !== PurchaseTransactionStage.Handover &&
                        purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
                        purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled && (
                            <Grid
                                lg={purchaseTransaction.stage === PurchaseTransactionStage.PendingApproval ? 12 : 6}
                                md={purchaseTransaction.stage === PurchaseTransactionStage.PendingApproval ? 12 : 6}
                                sm={12}
                                xs={12}
                                item
                            >
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                        classes={{ label: styles.cancelTransactionLabel }}
                                        onClick={onUpdateToCancel}
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:button.cancelTransaction')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        )}
                </>
            )}

            {currentUser.type === UserType.Admin &&
                purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
                purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled && (
                    <Grid xs={12} item>
                        <FormControl fullWidth>
                            <Button
                                className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                classes={{ label: styles.cancelTransactionLabel }}
                                onClick={onUpdateToCancel}
                                variant="contained"
                            >
                                {t('purchaseTransactionsPage:button.cancelTransaction')}
                            </Button>
                        </FormControl>
                    </Grid>
                )}
        </Grid>
    );
};

export default ValuationTab;
