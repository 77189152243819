import { Box, CardMedia, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useCurrentUser } from '../../../../Session';
import { usePublicFn } from '../../../../utilities/usePublic';
import getMenuListItems from '../getMenuListItems';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '50px',
            overflow: 'auto',
            flex: '1 1 auto',
        },
        itemWrapper: {
            display: 'flex',
            color: theme.palette.primary.contrastText,
            marginBottom: '16px',
            paddingLeft: '40px',
            height: '56px',
            '&:hover': {
                background: theme.palette.primary.main,
            },
        },
        selected: {
            background: theme.palette.primary.main,
        },
        item: {
            display: 'flex',
            maxWidth: '256px',
        },
        iconContainer: {
            minWidth: '24px',
            height: '24px',
            margin: 'auto',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        icon: {
            width: '24px',
            height: '24px',
            [`&.MuiSvgIcon-root`]: {
                fill: '#ffffff',
            },
        },
        tooltip: {
            fontSize: '0.875rem',
        },
    })
);

type SidePanelMenuProps = {
    open: boolean;
};

const SidePanelMenu = ({ open }: SidePanelMenuProps) => {
    const { t } = useTranslation(['common', 'homePage']);

    const location = useLocation();
    const history = useHistory();

    const user = useCurrentUser();
    const menuItems = useMemo(() => getMenuListItems(user.type), [user.type]);

    const styles = useStyles();
    const redirect = useCallback((path: string) => () => history.push(path), [history]);

    const isActivePath = useCallback(
        (path: string) => location.pathname.toLowerCase() === path.toLowerCase(),
        [location.pathname]
    );

    const publicFn = usePublicFn();

    return (
        <List className={styles.root}>
            {menuItems.map(menu => {
                const classes = isActivePath(menu.path) ? cs(styles.itemWrapper, styles.selected) : styles.itemWrapper;

                return (
                    <ListItem key={menu.text} className={classes} onClick={redirect(menu.path)} button>
                        <Box className={styles.item}>
                            <Tooltip
                                classes={{ tooltip: styles.tooltip }}
                                disableHoverListener={open}
                                placement="right"
                                title={t(`homePage:menuBar.${menu.text}`)}
                            >
                                <ListItemIcon className={styles.iconContainer}>
                                    <CardMedia
                                        className={styles.icon}
                                        image={publicFn(`assets/menuIcons/${menu.icon}`)}
                                    />
                                </ListItemIcon>
                            </Tooltip>
                            {open && <ListItemText primary={t(`homePage:menuBar.${menu.text}`)} />}
                        </Box>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default SidePanelMenu;
