import { isEmpty } from 'lodash/fp';
import { useCallback } from 'react';
import VehiclePrefillForm, { VehiclePrefillFormProps } from '../components/VehiclePrefillForm';
import CreateSaleTransactionHeader from './CreateSaleTransactionHeader';
import { Page, useCreateSalesTransactionState } from './CreateSalesTransactionStateProvider';
import { CreateSaleTransactionFormValues } from '.';

const CreateVehiclePrefillForm = () => {
    const {
        state: { prefillRaw, formValue: initialValues },
        actions,
    } = useCreateSalesTransactionState();

    const onComplete: VehiclePrefillFormProps<CreateSaleTransactionFormValues>['onComplete'] = useCallback(
        ({ raw, updatedValues }) => {
            if (!isEmpty(updatedValues)) {
                actions.setFormValue(updatedValues);
                actions.setPrefillRaw(raw);
            }
            actions.setCurrentPage(Page.VehicleForm);
        },
        [actions]
    );

    return (
        <VehiclePrefillForm
            formValues={null}
            header={<CreateSaleTransactionHeader currentPage={Page.VehiclePrefill} />}
            initialValues={initialValues}
            onComplete={onComplete}
            prefillRaw={prefillRaw}
        />
    );
};

export default CreateVehiclePrefillForm;
