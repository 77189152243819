import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SaleTransactionStage } from '../api';

export const defaultAllOption = 'all';

const useSalesTransactionStateFilterOptions = (allValue = defaultAllOption) => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return useMemo(
        () => [
            { value: allValue, label: t('saleTransactionsPage:filter.all') },
            { value: SaleTransactionStage.Drafted, label: t('saleTransactionsPage:filter.drafted') },
            { value: SaleTransactionStage.UpcomingBidding, label: t('saleTransactionsPage:filter.upcomingBidding') },
            { value: SaleTransactionStage.BiddingOpen, label: t('saleTransactionsPage:filter.biddingOpen') },
            { value: SaleTransactionStage.ForAwarding, label: t('saleTransactionsPage:filter.forAwarding') },
            { value: SaleTransactionStage.ForHandover, label: t('saleTransactionsPage:filter.forHandover') },
            { value: SaleTransactionStage.Completed, label: t('saleTransactionsPage:filter.completed') },
            { value: SaleTransactionStage.Rejected, label: t('saleTransactionsPage:filter.rejected') },
        ],
        [allValue, t]
    );
};

export default useSalesTransactionStateFilterOptions;
